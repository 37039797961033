$grid-gutter: 20px;

.c-block-grid {
  $root: &;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 ($grid-gutter / -2);

  &__item {
    max-width: 100%;
    min-height: 1px;
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
    flex-basis: 100%;
    flex-grow: 1;
    margin-bottom: $grid-gutter;

    #{$root}--cols-sm-1 & {
      @media screen and (min-width: 544px) {
        flex-basis: 50%;
        margin-bottom: 0;

        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4) ~ & {
          max-width: 50%;
        }
      }

      @media screen and (min-width: 992px){
        flex-basis: 25%;

        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4) ~ #{$root}__item {
          max-width: 25%;
        }
      }

      &:nth-child(n + 3) {
        @media screen and (min-width: 544px) {
          margin-top: $grid-gutter;
        }

        @media screen and (min-width: 992px) {
          margin-top: 0;
        }
      }
    }

    #{$root}--cols-sm-2 & {
      flex-basis: 50%;
      margin-bottom: 0;

      &:first-child:nth-last-child(4),
      &:first-child:nth-last-child(4) ~ #{$root}__item {
        max-width: 50%;
      }

      @media screen and (min-width: 992px){
        flex-basis: 25%;
        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4) ~ #{$root}__item {
          max-width: 25%;
        }
      }

      &:nth-child(n + 3) {
        margin-top: $grid-gutter;

        @media screen and (min-width: 992px) {
          margin-top: 0;
        }
      }
    }

    &:last-child {
      @media screen and (max-width: 543px) {
        margin-bottom: 0;
      }
    }
  }
}
