@import './styles/normalize';
@import './styles/reboot-customized';
@import './styles/fonts';
@import './styles/variables';
@import './styles/grid/grid';
@import './styles/paddings-margins';
@import './styles/utilities';
// Probably want to do something about those imports...
@import './styles/slick';
@import './styles/slick-theme';

html,
body {
	overscroll-behavior: none;
}

body {
	height: 100vh;
	overflow: auto;
	width: 100%;
	overflow-x: hidden;
}

/* Container */
.b-container {
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}

.b-container:after {
	content: '';
	display: table;
	clear: both;
}

@media screen and (min-width: 1900px) {
	.b-container {
		max-width: 1860px;
	}
}

_:-ms-fullscreen,
:root .b-shopping .cart,
_:-ms-fullscreen,
:root .b-shopping .delivery {
	height: 100%;
}

.b-shopping {
	$header-margin: -4px;
	bottom: 0;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: 16px;
	top: 142px;
	transform: translateX(302px);
	transition: ease-in-out 0.2s;
	will-change: transform;
	width: 302px;
	z-index: 2;

	&.noticeHidden {
		top: 114px;
	}

	::-webkit-scrollbar {
		width: 5px;
	}

	::-webkit-scrollbar-track {
		background: #f7f7f7;
	}

	::-webkit-scrollbar-thumb {
		background: #888;
	}

	.cart {
		position: static;
	}

	&.active>div {
		pointer-events: all;
	}

	@media (min-width: $screen-md) {
		will-change: top, transform;

		.sticky-header & {
			top: 94px;

			&.noticeHidden {
				top: 86px;
			}
		}

		&.cartLimitHeight {
			max-height: calc(100% - 119px);
		}
	}

	@media screen and (max-width: 991px) {
		top: 94px;

		&.noticeHidden {
			top: 64px;
		}

	}

	@media (min-width: 1360px) {
		will-change: top;
	}

	@media screen and (min-width: 1900px) {
		right: calc((100% - 1860px) / 2);
	}
}

.b-shopping-enter {
	transform: translateX(302px);
	opacity: 0;
}

.b-shopping-enter-active {
	transform: translateX(0px);
	transition: ease-in-out 0.2s;
	opacity: 1;
}

.b-shopping-enter-done {
	transform: translateX(0px);
	opacity: 1;
}

.b-shopping-exit {
	transform: translateX(0);
	opacity: 1;
}

.b-shopping-exit-active {
	transform: translateX(302px);
	transition: ease-in-out 0.2s;
	opacity: 0;
}

.b-shopping-exit-done {
	opacity: 0;

	>div {
		pointer-events: none;
	}
}

.b-shopping.active {
	transform: translateX(0);
	opacity: 1;
}

@media screen and (min-width: 992px) {
	.b-mobilenav {
		display: none;
	}
}

.slick-next,
.slick-prev {
	height: 100%;
	width: auto;
}

.slick-prev {
	padding-left: 8px;
}

.slick-next {
	padding-right: 8px;
}

.slick-prev:before,
.slick-next:before {
	font-family: 'slick';
	font-size: 40px;
	line-height: 1;
	color: #000;
	opacity: 0.75;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev:before {
	content: '‹';
}

[dir='rtl'] .slick-prev:before {
	content: '›';
}

.slick-next:before {
	content: '›';
}

[dir='rtl'] .slick-next:before {
	content: '‹';
}

#CookiebotWidget {
	display: none;
}