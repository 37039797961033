@import '../../../styles/variables';

.c-recipe-ingredients {
  width: 100%;
  padding: 0 map-get($spacers, 5) 0;

  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 0 5px;
    border-bottom: 1px solid $color-black-lighter;
    font-size: $font-size-base * 0.75;

    h3 {
      padding-left: 0;
      font-weight: 500;
      @media screen and (min-width: $screen-md-min) {
        padding-left: map-get($spacers, 4);
      }
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:nth-of-type(3) {
      padding-top: 0;
    }

    &:nth-last-of-type(2) {
      border-bottom: none;
    }

    &:last-of-type {
      border-bottom: none;
    }

    &--no-divider {
      border-bottom: none;
    }

    &--header {
      font-weight: 500;
      padding: 0;
    }
  }

  &__image {
    height: 5rem;
    padding: map-get($spacers, 1);

    > img {
      max-height: 100%;
    }
  }

  &__item-cost {
    font-size: $font-size-xsm;
    @media screen and (min-width: $screen-md-min) {
      font-size: $font-size-base;
    }
  }

  &__total {
    display: flex;
    flex-direction: column;
  }

  &__title {
    word-wrap: break-word;
    font-size: $font-size-sm;
    @media screen and (min-width: $screen-md-min) {
      font-size: $font-size-base;
      padding-right: map-get($spacers, 3);
    }
    .brand-package-quantity {
      font-size: $font-size-xxs;
      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-sm;
      }
    }
  }

  &__checkbox {
    display: flex;
    justify-content: flex-end;
  }
}
