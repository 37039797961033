@import '../../../../styles/variables';

.c-unavailableconfirm {
  background:#F7F7F7;
  max-width: 550px;
  width: 100%;
  padding: 15px 25px;
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }


  &__productwrap {
    position: relative;
    margin: 20px 0;
    box-shadow: 0px 1px 38px #b5b5b5;
    display:flex;
    flex: 1;
  }

  &__products {
    max-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;
    flex:1;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px; /* Mostly for vertical scrollbars */
      height: 10px; /* Mostly for horizontal scrollbars */
    }
  &::-webkit-scrollbar-thumb { /* Foreground */
      background: #888;
    }

    @media screen and (max-width: 480px) {
      max-height: 300px;
    }
  }

  &__product {
    display: flex;
    background: #fff;
    border-bottom: 1px solid #fbfbfb;
    padding: 15px;
    flex: 1;
    align-items: center;
    font-size: 14px;

    @media screen and (max-width: 480px) {
      padding: 5px;
      font-size: 12px;
    }

    img {
      max-width: 100%;
      max-height: 40px;
      max-width: 40px;

      @media screen and (max-width: 480px) {
        max-height: 30px;
        max-width: 30px;
      }

    }
  }

  &__productimage {
    display: flex;
    max-width: 100%;
    width: 50px;
    text-align: center;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
  }

  &__disclaimer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(101, 101, 101, 0.1);
  }
  &__disclaimerinner {
    background: #ededed;
    display: inline-block;
    padding: 15px;
    border-top: 3px solid #ececec;
  }
  &__error {
    background: #fffbe7;
    padding: 10px;
    font-weight: 400;
    margin-bottom: 10px;
    color:red;
  }

  &__description {
    flex-direction: column;
  }

  &__priceSection{
    margin-left: auto;
    display:flex;
  }

  &__priceDiff {
    padding-right: 8px;
    text-decoration: line-through;
  }

  &____descriptive {
    font-size: 11px;
  }

  &__price {
    font-weight: 500
  }

  &__loading {
    height: 200px;
    display:flex;
    justify-content: center;
    align-items:center;
  }

  &__buttons {
    display:flex;
    & > :first-child{
      margin-right: 10px;
    }
  }

}


