@import '../../../styles/_variables.scss';

.c-recipe-head,
.c-recipe-head__actions {
  padding: 20px;
  @media screen and (max-width: 767px) {
    padding: 10px 10px 20px;
  }
  @media screen and (max-width: 480px) {
    padding: 10px 10px 20px;
  }
}
.wwpoints {
  background: #4756ae;
  margin-right: 3px;
  border-radius: 50px;
  display: inline-block;
  font-weight: 800;
  color: #fff;
  font-size: 12px;
}

.c-recipe-head {
  background: white;
  @media screen and (min-width: $screen-md-min) {
    border-bottom: 1px solid $color-black-lighter;
  }
  h1 {
    font-weight: 500;
    margin: 0;
    margin-top: 10px;

    @media screen and (max-width: 480px) {
      font-size: 19px;
    }
    @media screen and (min-width: 768px) {
      font-size: 28px;
    }
  }
  &__actions {
    display: flex;
    padding-bottom: 20px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }
  &__tags {
    flex-grow: 2;
    @media screen and (max-width: 767px) {
      order: 2;
    }
    align-self: flex-end;
    .c-bubbletag {
      border-radius: 0;
      font-size: 12px;
      border: solid 1px rgba(0, 0, 0, 0.1);
    }
    .c-bubbletag:not(.active) {
      background-color: #fff;
      color: #000;
    }
    :not(:last-child) {
      margin-right: 5px;
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 10px;
    }
  }
  &__shop {
    flex-grow: 1;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      order: 1;
      margin-bottom: 10px;
      margin-right: 100%;
    }
  }
  &__smartpoints {
    display: flex;
    margin-top: 15px;
    align-items: center;
  }
  &__smartpoint {
    background: #f00;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-right: 3px;
    display: flex;
    justify-content: center;
    font-weight: 800;
    color: #fff;
    font-size: 12px;
    align-items: center;
  }
  &__smartpointaction {
    text-decoration: underline;
    cursor: pointer;
  }

  &__link {
    text-decoration: underline;
    margin-left: 5px;
    &:hover {
      color: #0073d6;
      cursor: pointer;
    }
  }

  &__personalpoint {
    padding: 4px 12px;
  }
  &__singlepoint {
    padding: 4px 10px;
  }

  &__personalpoint-text {
    font-size: 12px;
    margin-left: 5px;
    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }
  &__button {
    text-align: right;
    white-space: nowrap;
    height: 32px;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      color: #015b93;
      padding-top: 10px;
      font-size: 12px;
    }
    button {
      @media screen and (min-width: $screen-xs) {
        width: 151px;
      }
    }
  }

  .time {
    font-size: 15px;
    font-weight: 500;

    text-align: right;
    flex-grow: 1;
    min-width: 100px;
    .text {
      position: relative;
      top: -5px;
      padding-left: 10px;
    }
  }

  &__meta {
    display: flex;
    align-items: center;
    margin-top: 7px;
  }

  .portions {
    margin: 20px 0;
    display: inline-block;

    @media screen and (min-width: 768px) {
      margin-right: 20px;
    }
  }

  .price {
    font-weight: 600;
    @media screen and (max-width: 767px) {
      font-size: $font-size-base;
    }
  }

  .disclaimer {
    font-size: 10px;
    color: black;
    padding-bottom: 15px;
    @media screen and (min-width: 768px) {
      text-align: right;
    }
  }

  .only-for-print {
    display: none;
  }

  .c-favoritebtn {
    position: absolute;
    top: 10px;
    left: 10px;
  }
}

.c-recipe-head-actions {
  align-items: center;
  border-bottom: none;
  &__align-filters-and-add-to-cart {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &__portions-and-customisation {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    p {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
  &__portions {
    margin-right: 40px;
  }
  &__customisation {
    & .c-recipe-head__tags-button {
      .c-cmdbtn:active,
      .c-cmdbtn.active {
        margin-top: 2px;
      }
      button {
        margin-right: 10px;
        border-radius: 2px;
        margin-bottom: 0;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  &__shop {
    &-inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .price {
        font-weight: 500;
        font-size: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-end;
    &__customisation {
      .c-recipe-head__tags-button {
        &__button-wrapper {
          display: flex;
          justify-content: flex-end;
          button {
            margin-bottom: 10px;
            margin-right: 10px;
            padding: 0;
            min-width: 86px;
            max-width: 138px;
          }
        }
      }
    }
    &__portions-and-customisation {
      width: 100%;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      & > * {
        margin-bottom: 20px;
      }
      p {
        text-align: left;
      }
    }

    &__portions {
      margin-right: 20px;
    }

    &__shop-inner-wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      & > .price {
        margin-right: 20px;
        font-size: $font-size-base;
      }
    }
  }
  @media screen and (max-width: 480px) {
    &__portions-and-customisation {
      display: flex;
      flex-direction: column;
    }
    &__portions {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: end;
    }
    &__customisation {
      width: 100%;
      .c-recipe-head__tags-button {
        display: flex;
        flex-direction: column;
        &__button-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          button {
            margin-right: 5px;
            padding: 0;
            width: 100%;
            max-width: none;
          }
        }
      }
    }
    &__shop-inner-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      & > .price {
        font-size: $font-size-base;
        width: 100%;
        margin-right: 0;
      }
    }
  }
}

.safari-button-height {
  min-height: 31px;
}
