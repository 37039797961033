.c-stdform {
  // height: inherit;

  &.loading {
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: 2;
    }

    & > * {
      opacity: .3;
    }

    .c-stdform__spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      opacity: 1;
    }
  }

  .formgroup:not(:last-child) {
    margin-bottom: 15px;
  }
  .formfield {
    margin-bottom: 15px;

    ::placeholder {
      opacity: .5;
    }

    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }

    label {
      &:after {
        display: block;
        content: "";
      }

      .hint {
        color: #333;
        opacity: .8;
        font-size: 11px;
        float: right;
        padding-top: 5px;
      }
    }

    &.select label:after,
    &.textarea label:after {
      display: block;
      content: "";
    }

    &.text input,
    &.textarea textarea {
      padding: 4px 7px;
      font-size: 12px;
      border: 1px solid #e7e7e7;
      border-radius: 2px;
      background: white;
    }

    &.text input {
      height: 32px;
    }

    &.textarea {
      position: relative;

      textarea {
        height: 75px;
        min-height: 75px;
        padding-top: 7px;
        resize: vertical;
        line-height: 1.5;
      }
    }

    &.text input:focus,
    &.textarea textarea:focus {
      border-color: #a7a7a7;
      color: inherit;
    }

    .textarea-counter {
      position: absolute;
      right: 0;
      bottom: 0;
      display: block;
      margin: 0;
      padding-right: 1em;
      padding-bottom: 1em;
      text-align: right;
      font-size: 12px;
      font-weight: 500;
      color: #333;
      pointer-events: none;
    }

    &.invalid {
      input,
      textarea {
        border-color: #e4777a;
        color: #e4777a;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .required-info {
    margin-bottom: 15px;
    font-size: 14px;
    white-space: nowrap;
  }

  .errors {
    display: block;
    background-color: #fff;
    border: 1px solid #e4777a;
    margin-bottom: 1em;
    padding: 1em;

    ul {
      list-style: outside;
      margin-left: 1em;

      + ul {
        margin-top: 15px;
      }
    }

    li {
      display: list-item;
      margin-bottom: 15px;
      font-size: 14px;
      color: #000;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .warning {
    padding: 20px;
    color: #333;
    background: #fffae2;
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .success {
    padding: 20px;
    color: #333;
    background: #ddefce;
    font-size: 12px;
    line-height: 1.2;
  }
}
@media (max-width: 767px) {
  .c-stdform .formfield.text input, .c-stdform .formfield.textarea textarea {
    font-size:16px;
  }
}
@media screen and (max-width: 543px) {
  .c-stdform .formfield {
    label {
      font-size: 12px;
    }
    &.text input, &.textarea textarea {
      width: 100%;
    }
    &.buttons button {
      width: 100%;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (min-width: 544px) {
  .c-stdform .formfield {
    &.text input, &.textarea textarea {
      width: 100%;
    }

    &.buttons button:not(:last-child) {
      margin-right: 15px;
    }
  }

  .c-panel.tight .c-stdform .formfield.buttons button {
    width: 100%;
  }
}
