@import '../../styles/_variables.scss';

.c-recipe {
  position: relative;
  background: #fff;

  &:after {
    display: block;
    content: '';
    clear: both;
  }
  &__section-wrapper {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: $screen-md-min) {
      flex-direction: row;
      max-width: 844px;
      margin: 0 auto;
    }
  }
  &__body {
    background-color: $color-white;
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
    @media screen and (min-width: 768px) {
      padding: 20px;
      &:after {
        display: block;
        content: '';
        clear: both;
      }
    }
  }

  &__tip {
    .inner-wrapper {
      font-size: 14px;
      padding: 40px 10px 40px;
      max-width: 600px;
      margin: 0 auto;
    }
  }

  &__tiptext {
    margin: -40px 0 0 50px;
    @media screen and (min-width: 768px) {
      margin-right: 54px;
    }
  }

  &__assortmentinfo {
    // background: #fff;
    padding: 0 1.875rem;
  }

  &__assortmentinfoinner {
    background: rgba(1,91,147,0.05);
    min-height: 280px;
    border: 2px dashed #015B93;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  &__assortmentinfoaction {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      color: #015B93;
      padding-top: 10px;
      font-size: 12px;
    }
  }
}

@media print {
  .b-shopping,
  .b-searchcontainer,
  .c-lazycontainer,
  .c-homelist,
  .b-secondary-nav,
  .b-mobilenav,
  .b-header,
  .c-recipe-head__actions,
  .c-recipe__tip,
  .b-footer,
  .portions,
  .zip-code,
  .c-confirm-modal,
  .c-panel {
    display: none !important;
  }

  h1 {
    background: #fff;
    font-size: 150% !important;
  }

  body,
  .time {
    background: #fff;
    font-size: 80% !important;
  }

  .only-for-print {
    display: block !important;
  }

  .logo,
  .image {
    z-index: 2;
  }

  .c-recipe-details__ingredients {
    float: left;
    width: 250px;
    font-size: 80%;
  }

  .c-recipe-directions {
    float: left;
    width: 350px;
    font-size: 85%;
    h2 {
      margin: 0px;
    }
  }
}
