@import '../../../styles/_variables.scss';

// Default button / Grey button
.c-cmdbtn {
  display: inline-flex;
  justify-content: center;
  color: $color-black;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  height: $button-default-height;
  line-height: $button-default-height;
  padding: 1px 20px;
  border: 0;
  border-radius: 2px;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  background-color: $color-button-grey-static;
  box-shadow: 0 1px 0 0 $color-button-grey-shadow;
  white-space: nowrap;
  align-items: center;

  &.lg {
    @media screen and (min-width: 480px) {
      font-size: 14px;
      height: 40px;
    }
  }

  &,
  &.primary,
  &.light,
  &.white,
  &.grey {
    &:active {
      background-color: $color-button-primary-hover;
      box-shadow: 0 2px 0 0 $color-button-primary-shadow;
      text-decoration: none;
    }
    @media (hover: hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover {
        background-color: $color-button-primary-hover;
        box-shadow: 0 2px 0 0 $color-button-primary-shadow;
        text-decoration: none;
      }
    }
  }

  &.blue {
    &:active {
      background-color: $color-button-blue-hover;
      box-shadow: 0 2px 0 0 $color-button-blue-shadow;
      text-decoration: none;
    }
    @media (hover: hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover {
        background-color: $color-button-blue-hover;
        box-shadow: 0 2px 0 0 $color-button-blue-shadow;
        text-decoration: none;
      }
    }
  }
  &[disabled] {
    padding-top: 0;
    cursor: initial;
    background-color: $color-button-grey-disabled;
    border: solid 1px $color-button-grey-disabled-accent;
    box-shadow: none;
    color: $color-button-grey-disabled-text;
  }

  &.disabled {
    opacity: 0.5;
  }

  &:active,
  &.active {
    background-color: $color-button-grey-active;
    box-shadow: 0 -1px 0 0 $color-button-grey-shadow;
  }

  &.secondary.active {
    margin-top: 0;
  }

  &.fullwidth {
    width: 100%;
  }

  &.fullwidthBreakPoint {
    @media screen and (min-width: 544px) {
      width: 100%;
    }
  }

  // Primary button
  &.primary {
    background: $color-button-primary-static;
    box-shadow: 0 1px 0 0 $color-button-primary-shadow;
    color: $color-black;

    &:active,
    &.active {
      //margin-top: -1px;
      background-color: $color-button-primary-active;
      box-shadow: 0 -1px 0 0 $color-button-primary-shadow;
    }

    &[disabled] {
      box-shadow: none;
      color: $color-black-transparent;
      background-color: $color-button-primary-disabled;
      border: solid 1px $color-button-primary-border;
    }
  }

  // Primary button
  &.prio {
    background: $color-button-prio;
    box-shadow: 0 1px 0 0 $color-button-prio-shadow;
    color: $color-white;
    font-weight: bold;

    &:active,
    &.active {
      //margin-top: -1px;
      background-color: $color-button-prio-active;
      // box-shadow: 0 -1px 0 0 $color-button-prio-shadow;
    }

    &[disabled] {
      box-shadow: none;
      color: $color-black-transparent;
      background-color: $color-button-prio-disabled;
      border: solid 1px $color-button-prio-border;
    }
  }

  &.blue {
    background: $color-button-blue;
    box-shadow: 0 1px 0 0 $color-button-blue-shadow;
    color: $color-white;
    font-weight: bold;

    &:active,
    &.active {
      //margin-top: -1px;
      background-color: $color-button-blue-active;
      // box-shadow: 0 -1px 0 0 $color-button-prio-shadow;
    }

    &[disabled] {
      box-shadow: none;
      color: $color-black-transparent;
      background-color: $color-button-blue-disabled;
      border: solid 1px $color-button-blue-border;
    }
  }
  // Tertiary button
  &.tertiary {
    padding-top: 0;
    font-weight: 400;
    background-color: $color-button-tertiary;
    box-shadow: 0 1px 0 0 $color-button-tertiary-shadow;
    border: solid 1px $color-button-tertiary-static-border;

    &:active {
      background-color: $color-button-tertiary;
      box-shadow: 0 1px 0 0 $color-button-tertiary;
      border: solid 1px $color-button-tertiary-hover-border;
    }

    @media (hover: hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover {
        background-color: $color-button-tertiary;
        box-shadow: 0 1px 0 0 $color-button-tertiary;
        border: solid 1px $color-button-tertiary-hover-border;
      }
    }

    &[disabled] {
      background-color: $color-button-tertiary;
      box-shadow: 0 1px 0 0 $color-button-tertiary-shadow;
      border: solid 1px $color-button-tertiary-disabled-border;
    }
  }

  // White button
  &.light,
  &.white {
    padding-top: 0;
    background-color: $color-button-white-static;
    box-shadow: 0 1px 0 0 $color-button-white-static-shadow;

    &:active,
    &.active {
      background-color: $color-button-white-active;
      box-shadow: 0 -1px 0 0 $color-button-white-active;
      border: solid 1px $color-button-white-active;
    }

    &[disabled],
    &.disabled {
      background-color: $color-button-white-disabled;
      border: solid 1px $color-button-white-disabled-border;
      box-shadow: none;
    }
  }

  // Grey button
  &.grey {
    background-color: $color-button-grey-static;
    box-shadow: 0 1px 0 0 $color-button-grey-shadow;

    &:active,
    &.active {
      background-color: $color-button-grey-active;
      box-shadow: 0 -1px 0 0 $color-button-grey-shadow;
    }

    &[disabled],
    &.disabled {
      background-color: $color-button-grey-disabled;
      border: solid 1px $color-button-grey-disabled-accent;
      box-shadow: none;
    }
  }

  // Transparent button
  &.transparent {
    border: 1px solid #a7a7a7;
    border-radius: 2px;
    background-color: transparent;
    box-shadow: 0 1px 0 0 #e8e8e8;

    &:active,
    &.active {
      background-color: $color-button-grey-active;
      box-shadow: 0 -1px 0 0 $color-button-grey-shadow;
    }

    &[disabled],
    &.disabled {
      background-color: $color-button-grey-disabled;
      border: solid 1px $color-button-grey-disabled-accent;
      box-shadow: none;
    }
  }

  &__spinner {
    width: 26px;
    height: 26px;
    animation: rotate 0.75s linear infinite;
  }

  &__success {
    width: 14px;
    height: 14px;
  }

  &__label {
    display: flex;
    align-items: center;
    gap: 4px;

  }

  &__icon {
    margin-right: 6px;
  }

  &.success {
    background: $color-button-success-static;
    box-shadow: 0 -1px 0 0 $color-button-success-shadow;

    &:active {
      background-color: $color-button-success-hover;
      box-shadow: 0 1px 0 0 $color-button-success-shadow;
    }

    @media (hover: hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover {
        background-color: $color-button-success-hover;
        box-shadow: 0 1px 0 0 $color-button-success-shadow;
      }
    }

    &[disabled],
    &.disabled {
      background: $color-button-success-static;
      box-shadow: 0 -1px 0 0 $color-button-success-shadow;
    }
  }
}
