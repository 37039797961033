.scrollable {
  display: inherit;
  max-height:100%;
  min-height:1px;
  &__container{
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;

    .c-grid.cards {
      margin: 0;
    }
  }
  &.full-height {
      height:100%;
  }
}
