@import '../../styles/variables';

.b-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 3;
 width: 100vw;


  &.is-checkout {
    box-shadow: none;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .b-container {
    height: $header-small-height;
    position: relative;
    transition: height 0.2s ease-in-out;

    @media screen and (min-width: 992px) {
      height: $header-big-height;
      will-change: height;

      .sticky-header & {
        height: $header-small-height;
      }
    }
  }

  &.sticky-header {
    @media screen and (min-width: 891px) {
      .logo {
        .logo-portrait {
          @media screen and (min-width: 992px) {
            transition: transform 0.2s ease-in-out;
            transform: translateY(-100%);
          }
        }
        .logo-landscape {
          visibility: visible;
          transition: transform 0.2s ease-in-out;
          transform: translateY(-116px);
        }
      }
    }
  }

  .mast {
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    height: 53px;

    @media screen and (min-width: 992px) {
      width: calc(100% - 196px - 30px);
    }

    @media screen and (max-width: 991px) {
      width: calc(100% - 63px - 10px);
      bottom: 8px;
      height: auto;
    }
  }
}

.header-placeholder {
  height: 66px;
}

.header-notice-placeholder {
  height: 114px;
  @media screen and (min-width: 992px) {
    height: 166px;
  }
}


.header-summary-info {
  max-width: 1860px;
  padding: 0 10px;
  &__wrapper{
    min-height: 49px;
  
  }
  
  &__wrapperLarge{
    max-height: 179px;
    @media screen and (min-width: 480px){
      max-height: 157px;
    }

    @media screen and (min-width: 992px){
      max-height: 119px;
    }
  }
  &__wrapperSmall{
    max-height: 114px
  }
  &__wrapperExtraLarge {
    max-height: 310px;
    @media screen and (min-width: 480px){
      max-height: 270px;
    }

    @media screen and (min-width: 992px){
      max-height: 188px;
    }
  }
  &__container{
   
    display: flex;
    flex-direction: column;
    align-items: center;
   
  }
  &__productContainer{
    @media screen and (min-width: 992px) {
      padding:0;
      width: calc(100% - 206px - 8px * 2);
      margin-left: calc(206px + 8px);
    }
    @media screen and (min-width: 1360px) {
      width: calc(100% - 220px - 300px - 10px * 2);
    }
    @media screen and (min-width: 1900px) {
      width: calc(100% - 206px - 300px - 6px * 2);
    }

  }
  &__with-sidebar{
    @media screen and (min-width: 992px) {
      padding:0;
      width: calc(100% - 206px - 16px * 2);
      margin-left: calc(206px + 16px);
    }
   
  }
}
