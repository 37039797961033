/* Component: customer service */
@import '../../../styles/variables';

// Mobile first => default
.c-contactcard {
  $root: &;
  background: white;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    padding: 10px;
    margin: 0 -10px;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 768px) {
    padding: 20px;
    width: calc(50% - 10px);
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  a {
    color: #0071b9;
    &:hover {
      text-decoration: underline;
    }
  }

  h2 {
    @media screen and (max-width: 767px) {
      font-size: 23px;
      margin: 0 0 20px;
    }
    @media screen and (min-width: 768px) {
      font-size: 28px;
      margin: 0 0 35px;
    }
  }

  &__header {
    font-size: $h3-font-size;
    font-weight: 600;
    margin-bottom: 15px;

    #{$root} {
      &__icon {
        margin-right: 5px;
      }
    }
  }

  &__link {
    display: block;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__icon {
    display: inline-block;

    img {
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .c-richtext {
    font-size: 14px;
    img {
      vertical-align: middle;
    }
  }
}

@media screen and (min-width: $screen-xs) {
  .c-contactcard {
    &__header {
      font-size: $h2-font-size;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: $screen-xs-max) {
  .c-contactcard {
    margin: 0;
    padding: 20px;
    border-top: 2px solid $color-black-lightest;
    h2 {
      margin-top: 0;
      line-height: 1rem;
    }
    & > * {
      margin-bottom: 10px;
    }
    & > *:last-child {
      margin-bottom: 0;
    }
    &__link {
      font-size: $font-size-small;
      &:not(:last-child) {
        margin-bottom: 5px;
        line-height: 2.3rem;
      }
    }
    &__icon img[src*='cart'] {
      width: 24px;
      margin-top: -6px;
    }
  }
}
