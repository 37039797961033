@import '../../../styles/variables';

.c-category-suggestions {
  & > a:hover {
    text-decoration: underline;
  }
  &__title {
    width: 100%;
    font-size: $font-size-base;
    @media screen and (min-width: $screen-md-min) {
      font-size: $font-size-sm;
    }
    &--count {
      color: rgb(123, 123, 123);
      font-weight: $font-weight-base;
      font-size: $font-size-xsm;
      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-xsm;
      }
    }
  }
  &__categorized-items {
    font-size: $font-size-sm;
    background-color: transparent;
    padding: map-get($spacers, 2);
    margin-left: -(map-get($spacers, 2));
    margin-right: -(map-get($spacers, 4));
    @media screen and (min-width: $screen-md-min) {
      margin-left: 0;
      margin-right: 0;
      padding: 0;
      font-size: $font-size-xsm;
    }
  }

  &__categorized-item {
    margin-bottom: map-get($spacers, 2);
    &:hover {
      text-decoration: underline;
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.c-category-suggestions--is-main-area {
  > a h3 {
    margin-left: map-get($spacers, 2);
  }
  .c-category-suggestions__categorized-items {
    background-color: $color-white;
    padding: map-get($spacers, 2);
  }
}

.c-category-suggestions--is-sidebar {
  margin-top: map-get($spacers, 6);
  &:first-of-type {
    @media screen and (min-width: $screen-md-min) {
      margin-top: 0;
    }
  }
  .c-category-suggestions__categorized-item {
    &:first-of-type {
      @media screen and (min-width: $screen-md-min) {
        margin-top: map-get($spacers, 3);
      }
    }
  }
}
