.c-searchresultcontent {
  &__item {
    background: #fff;
    padding: 10px 20px;
    border-bottom: 1px solid #f3f3f3;
    &:hover {
      text-decoration:underline;
    }
  }
}
