.c-recipelist { margin-bottom: 20px; }
.c-recipelist .c-browseoptions .filters { display: none; }
.c-recipelist .listcount { color: #054066; margin: 20px 0 10px; }

@media screen and (max-width: 543px) {
	.c-recipelist .listcount { font-size: 18px; font-weight: 600; }
}
@media screen and (min-width: 544px) {
	.c-recipelist .listcount { font-size: 23px; font-weight: 500; }
}
@media screen and (min-width: 450px) {
	.c-recipelist .card { width: calc(100% / 2); }
}
@media screen and (min-width: 1200px) {
	.c-recipelist .card { width: calc(100% / 3); }
}
@media screen and (min-width: 1360px) {
	.c-recipelist .card { width: calc(100% / 2); }
}
@media screen and (min-width: 1530px) {
	.c-recipelist .card { width: calc(100% / 3); }
}
@media screen and (min-width: 1800px) {
	.c-recipelist .card { width: calc(100% / 4); }
}
