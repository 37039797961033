.c-roundspinner {
  display: flex;
  justify-content: center;
  
  img {
    width: 26px;
    height: 26px;
    animation: rotate 0.75s linear infinite;
  }
}
