.b-delivery-district-list {
  &__title {

  }

  &__body {

  }

  &__picture {

  }

  &__top-blocks {

  }

  &__sites {
    margin-bottom: 40px;
  }

  &__sites-navbar {
    margin-bottom: 40px;
  }

  &__sites-nav-list {
    margin: 0 -5px;

    @media screen and (min-width: 992px) {
      text-align: center;
    }
  }

  &__nav-item {
    display: inline-block;
  }

  &__nav-link {
    display: block;
    padding: 0 5px;
    color: #0071b9;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  &__columns {
    columns: 2;

    @media screen and (min-width: 992px) {
      columns: 4;
    }
  }

  &__site-group {
    page-break-inside: avoid;
    break-inside: avoid;
    margin-bottom: 20px;
    .anchor {
      top:-68px;
      height:0px;
      display:block;
      position: relative;
    }
  }

  &__site-group-title {
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__site-group-item {

  }

  &__site-group-link {
    color: #0071b9;
    text-decoration: underline;
    text-transform: capitalize;
  }

  &__bottom-blocks {

  }
}
