@import '../../styles/variables';

// Mobile first => default
.c-customerservice {
  .c-stdselect {
    width: 100%;
  }
  &__cards {
    margin: 0;
    padding: 0;
  }
  &__picker {
    background-color: $color-white;
  }
}

// Mobile up to 767
@media screen and (max-width: $screen-xs-max) {
  .c-customerservice {
    &__picker {
      padding: 20px;
      border-top: 2px solid $color-black-lightest;
    }
  }
}

// Tablet and above
@media screen and (min-width: $screen-sm-min) {
  .c-customerservice {
    &__tabs {
      margin-bottom: 40px;
      max-width: 933px;
    }

    &__formparagraph {
      font-size: 14px;
      margin-bottom: 30px;
    }
  }
}

// Tablet
@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .c-customerservice {
    &__cards,
    &__blocks {
      margin: 20px 0 50px;
    }
  }
}

// Desktop and above
@media screen and (min-width: $screen-md-min) {
  .c-customerservice {
    &__cards,
    &__blocks {
      margin: 20px 0 20px;
    }
  }
}
