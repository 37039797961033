.c-category-filters-group {
  display: block;
  margin: 10px 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #f7f7f7;

  &__heading {
    margin-bottom: 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
    list-style: none;
  }

  &__item {
    display: block;
    flex: 0 0 auto;
    margin: 0 5px 10px;
    padding: 0;
  }
}
