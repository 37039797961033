@import '../../../styles/_variables.scss';

.c-minusbtn {

  &:not(.loading) {
    &:before {
      display: block;
      content: "";
      position: absolute;
      height: 2px;
      width: 16px;
      background: $color-black;
      left: 8px;
      top: 15px;
    }

    &:active {
      &:before {
        top: 14px;
      }
    }

    &.small{
      &:before{
        left: 6px;
        top: 12px;
        height: 2px;
        width: 12px;
      }
    }
  }
}
