.buy-button {
  min-height: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  .addtocart-animation-enter {
    opacity: 0;
    display: none;
  }

  .addtocart-animation-enter-active {
    transition: ease-in-out 0.4s;
    opacity: 1;
    display: flex;
  }

  .addtocart-animation-exit {
    opacity: 1;
    display: flex;
  }

  .addtocart-animation-exit-active {
    transition: ease-in-out 0.4s;
    opacity: 0;
    display: none;
  }

  .changeqty-animation-enter {
    opacity: 0;
    display: none;
  }

  .changeqty-animation-enter-active {
    transition: ease-in-out 0.4s;
    opacity: 1;
    display: flex;
  }

  .changeqty-animation-enter-done {
    opacity: 1;
    display: flex;
  }

  .changeqty-animation-exit {
    opacity: 1;
    display: flex;
  }

  .changeqty-animation-exit-active {
    transition: ease-in-out 0.4s;
    opacity: 0;
    display: none;
  }
}
