.c-flyouttabs {
  &__head {
    display: flex;
    @media screen and (min-width: 768px) {
      margin-bottom: 13px;
      border-bottom: 4px solid white;
    }
  }

  &__head-item {
    display: inline-block;
    padding: 16px 20px 12px;
    font-size: 14px;
    background-color: #f7f7f7;
    cursor: pointer;

    @media screen and (min-width: 768px) {
      margin-right: 5px;
    }

    &--active {
      background-color: white;
      font-weight: 600;
    }
  }

  &__tab {
    display: none;

    &--active {
      display: block;
    }
  }
}

// /* Component: tabbed content */
// .c-tabbedcontent .tab {  }
// .c-tabbedcontent .tab.active,
// .c-tabbedcontent .content {  }
// .c-tabbedcontent .tab.active {  }
// .c-tabbedcontent .content { padding: 20px; }
// .c-tabbedcontent .content:not(.active) { display: none; }
// .c-tabbedcontent .content:after { content: ""; display: table; clear: both; }
// @media screen and (max-width: 767px) {
// 	.c-tabbedcontent .tab .lessimportant { display: none; }
// }
// @media screen and (min-width: 768px) {
// 	.c-tabbedcontent .tabs {  }
// 	.c-tabbedcontent .tab { margin-right: 5px; }
// }
