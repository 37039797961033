$dot-size: 18px;
$dot-padding: 3px;

.c-spinner {
  $root: &;

  display: flex;
  justify-content: space-between;
  width: ($dot-size + $dot-padding) * 3;
  height: $dot-size;

  &__left,
  &__middle,
  &__right {
    width: $dot-size;
    height: 100%;
    background-color: #e7e7e7;
    border-radius: 50%;
    transform: scale(0);
    animation: spinner-animation 1.8s infinite ease-in-out both;
    will-change: transform;

    #{$root}--dark & {
      background-color: #333;
    }
  }

  &__left {
    animation-delay: -0.2s;
  }

  &__right {
    animation-delay: 0.2s;
  }
}

@keyframes spinner-animation {
  0%,80%,100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
