.b-text-with-image {
  @media screen and (min-width: 544px) {
    display: flex;
  }

  &__column {
    @media screen and (min-width: 544px) {
      flex: 0 0 50%;
      max-width: 50%;
      min-height: 1px;
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: auto;
  }

  &__text {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    text-align: center;
  }
}
