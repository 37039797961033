@import '../../../styles/variables';

.c-search-meta-completions {
  font-size: $font-size-xsm;
  padding: 0;
  margin: 0 -10px;
  @media screen and (min-width: $screen-md-min) {
    flex-wrap: wrap;
    overflow-x: none;
    margin: 0;
  }
  .slick-slide {
    margin-right: 10px;
  }
  .chip-wrapper {
    display: flex;
    align-items: flex-start;
  }
  &__item {
    border: 1px solid $color-black-lighter;
    background-color: $color-white;
    padding: 5px 10px;
    margin: 0 10px 10px;
    border-radius: map-get($spacers, 3);
    white-space: nowrap;
    flex: 0 0 auto;
    @media screen and (min-width: $screen-md-min) {
      margin: 0 10px 10px 0;
    }
    &:hover {
      background-color: $color-button-primary-hover;
      border-color: $color-button-primary-shadow;
      cursor: pointer;
    }
  }
}
