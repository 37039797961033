@import '../../../styles/variables';

.c-productdetails {
  position: relative;

  @media screen and (max-width: 767px) {
    padding: 10px 0;
  }

  .details-block {
    background: #fff;
    display: flex;
    gap: 20px;
    line-height: 1.3;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    position: relative;

    @media screen and (max-width: $screen-xs) {
      flex-direction: column-reverse;
      padding: 16px;
    }

    .push-to-bottom {
      height: auto;
      margin-top: 10px;
    }

    h1 {
      margin: 0;
    }

    .left {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .info-container {
        display: flex;
        gap: 8px;
      }

      .buttons-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .c-favoritebtn {
          position: unset;
        }
      }
    }

    .right {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      width: 300px;

      @media screen and (max-width: $screen-xs) {
        width: 100%;
      }

      .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        min-height: 300px;

        img {
          height: auto;
          max-width: 100%;
          max-height: 300px;
        }

        .price-stripe-container {
          position: absolute;
          left: 0;
          top: 0;
        }

        .markings {
          position: absolute;
          top: 0;
          right: 0;

          img {
            height: auto;
            width: 30px;
          }
        }
      }

      .price-tag-container {
        display: flex;
        justify-content: center;
        margin-top: -40px;
      }
    }

    .max-amount {
      font-size: 10px;
      color: #e02721;
      margin-top: 4px;
      margin-bottom: 4px;
      transition: 0.3s;
    }

    .visible {
      opacity: 1;
    }

    .hidden {
      opacity: 0;
    }
  }

  .c-productdetails__table {
    border-collapse: collapse;
    width: 100%;

    td {
      border: 1px solid $color-grey;
      padding: 8px;
    }
  }
}
