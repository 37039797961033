.c-base-block {
  padding-left: 10px;
  padding-right: 10px;

  @media screen and (max-width: 991px) {
    padding: 0;
  }

  &--no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  // Margin
  &--m {
    &-standard {
      margin-bottom: 40px;

      @media screen and (min-width: 992px) {
        margin-bottom: 80px;
      }
    }

    &-tight {
      margin-bottom: 20px;
    }

    &-flush {
      padding-left: 0;
      padding-right: 0;
    }
  }

  // Width
  &__inner {
    &--w {
      &-narrow {
        max-width: 844px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    // Background color
    &--bg {
      $bg-colors: (
        pink: #fbdede,
        yellow: #ffe423,
        white: #fff,
        grey: #e7e7e7,
        darkgrey: #464646,
        lightblue: #e6f3fc
      );

      @each $name, $hex in $bg-colors {
        &-#{$name} {
          background-color: $hex;
        }
      }
    }

    // Special padding for colored RichTextGrid boxes
    &--padding-text {
      padding: 20px 15px;

      @media screen and (min-width: 544px) {
        padding: 30px 20px;
      }
    }

    // Special padding for colored boxes
    &--padding-sides {
      padding-left: 15px;
      padding-right: 15px;

      @media screen and (min-width: 544px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}
