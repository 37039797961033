.changeqty {
  display: flex;
  align-items: center;
  justify-content: space-around;

  &.small {
    width: 92px;
  }

  &.noMargin {
    margin: 0;
  }

  .indicator {
    align-items: center;
    border: solid 1px #e7e7e7;
    background: #fff;
    border-radius: 62px;
    display: flex;
    height: 31px;
    //                                                                            |
    // space-evenly is not supported in edge yet. when it is uncomment and remove V
    //justify-content: space-evenly;
    justify-content: space-around;
    padding: 0 10px;
    // -->
    width: 58px;
    &.no-icon {
      width: 31px;
      height: 24px;
      border-radius: 12px;
    }
  }

  .icon {
    display: block;
    line-height: 1;

    img {
      display: block;
    }
  }
  .qty {
    position: relative;
  }
}
