@import '../../styles/_variables.scss';

.c-tag {
    font-size: 12px;
    background-color: #fff;
    color: $color-black;
    display: inline-block;
    padding: 6px 6px 3px 10px;
    border-radius: 15px;
    border: 1px solid #c7c7c7;

    &:hover {
      background: $color-black-lighter;
      cursor: pointer;
    }
    &.disabled {
      background: #000;
      pointer-events: none;
    }
    .c-check {
        margin-right: 4px;
    }
    .c-minicross {
      top: -1px;
      margin-left: 10px;
    }


}
