/* Component: article */
.c-article {
  margin-bottom: 20px;

  @media screen and (max-width: 767px) {
    //margin: 0 -10px;
  }

  &__content {
    margin-top: 20px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }

    @media screen and (min-width: 992px){
      margin-top: 40px;
    }

    &:first-of-type {
      margin-top: 0;
    }
  }

  &__mainbody {
    margin: 20px 0;
    padding-left: 10px;
    padding-right: 10px;

    @media screen and (min-width: 992px){
      margin: 40px 0;
    }
  }

  &__byline {
    border-top: 4px solid #ffe522;

    @media screen and (max-width: 767px) {
      padding: 20px 10px;
      //margin: 0 -10px;
    }
    @media screen and (min-width: 768px) {
      display: flex; padding: 20px;
    }
    @media screen and (min-width: 1500px) {
      margin: 0 -20px;
    }
    .author {
      font-size: 14px;
      a { text-decoration: underline; }
    }

    .actions {
      @media screen and (min-width: 768px) {
        flex: 1; text-align: right;
      }

    }
  }

}
