.limitedStock {
  background-color: #fff7d1;
  padding: 3px 8px;
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
  &:hover {
    .limitedStockTooltip {
      display: block;
    }
  }
}

.limitedStockTooltip {
  position: absolute;
  display: none;
  background: white;
  box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  bottom: 30px;
  left: -10px;
  padding: 10px;
  border-radius: 10px;
  width: 160px;
  text-align: center;
  font-size: 12px;
  &::after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 50%;
    width: 17px;
    height: 17px;
    border-radius: 0 0 2px 0;
    transform: rotate(45deg) translate(-8px, 50%);
    background: linear-gradient(-45deg, white 50%, transparent 50%);
  }
  @media (min-width: 768px) {
    left: -30px;
    width: 210px;
  }
}
