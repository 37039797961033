@import '../../styles/variables';

.b-secondary-nav {
  bottom: 0px;
  left: 10px;
  padding: 0px 0 64px 0;
  position: fixed;
  top: 86px;
  transition: top ease-in-out 0.2s;
  width: 196px;
  will-change: top;
  z-index: 1;

  .sticky-header & {
    top: 94px;

    &.noticeHidden {
      top: $header-small-height;
    }
  }

  // &.noticeHidden {
  //   top: $header-big-height;
  // }

  @media (max-width: 991px) {
    display: none;
  }

  @media screen and (min-width: 1900px) {
    left: calc(10px + ((100vw - 1860px) / 2));
  }
}
