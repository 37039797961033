.c-selectedfilters {

  .header {
    font-weight: 500;
    display: inline-block;
    margin-right: 10px;

    @media screen and (min-width: 992px){
      font-size: 14px;
    }
    @media screen and (max-width: 991px) {
      font-size: 12px;
    }
  }
  .c-tag {
    margin: 5px 10px 5px 0;
  }
}
