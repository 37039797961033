.b-video-block {
  position: relative;
  video {
    width:100%;
  }
  &__image {
    &:hover {
      opacity: .8;
      cursor: pointer;
    }
  }

  .y-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &__togglebtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 1;
  }
  &__closebtn{
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
  }
}
