.c-confirm-modal {
  display: none;
  position: fixed;
  text-align: center;
  overflow-y: auto;
  z-index: 6;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.5);
  justify-content: center;
  align-items: center;

  &--open {
    display: flex;
  }

  &__inner {
    margin: 15px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    opacity: 1;
    max-width: 450px;
  }

  &__head {
    padding: 15px 30px;
    border-top: 3px solid #ffe522;
    background-color: #fffae2;
    font-weight: 500;
  }

  &__body {
    padding: 30px 30px 20px;
  }

  &__buttons {
    display: flex;
    gap: 16px;
    flex:1;
    > * {
      flex:50%;
    }
  

  }
}
.c-modal-title{
  font-size:18px;
  font-weight: 600;
  margin: 0;
}
