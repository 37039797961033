@import '../../../styles/variables';

.c-productsuggestion {
  background-color: $color-white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: map-get($spacers, 3);
  border-bottom: 1px solid $color-black-lighter;
  position: relative;

  &__markings {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
  }

  &__info {
    display: grid;
    grid-template-columns: auto auto;
  }

  &__limitedStock {
    grid-row: 2;
    grid-column: span 2;
    margin-top: 10px;
  }

  &__image {
    width: 60px;
    height: 60px;
    min-width: 60px;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    margin: 0 map-get($spacers, 3) 0 0;
  }

  &__meta {
    margin-right: map-get($spacers, 2);
  }

  &__name {
    font-size: $font-size-sm;
    word-wrap: break-word;
    @media screen and (min-width: $screen-md-min) {
      font-size: $font-size-base;
    }
  }

  &__description {
    font-size: $font-size-xsm;
    color: rgb(123, 123, 123);
  }

  &__price-and-buy {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    height: 100%;
    @media screen and (min-width: $screen-md-min) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__price {
    margin-bottom: 10px;
    @media screen and (min-width: $screen-md-min) {
      margin-right: map-get($spacers, 4);
      margin-bottom: 0;
    }
  }

  &__buy {
    height: 36px;
    min-width: 120px;
    display: flex;
    justify-content: flex-end;
    @media screen and (min-width: $screen-md-min) {
      justify-content: center;
    }
  }
}

// @TODO: not a good way, but for now until we refactor search.
.c-productsuggestion-card__container {
  p {
    word-break: break-word;
  }
}
