/* Component: rich text */
.c-richtext {
  line-height: 1.45;

  @media screen and (min-width: 544px) {
    letter-spacing: 0.5px;
  }

  > *:first-child,
  &__inner *:first-child {
    margin-top: 0;
  }

  > *:last-child,
  &__inner *:last-child {
    margin-bottom: 0;
  }

  > *:empty,
  &__inner > *:empty {
    display: none;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin: 0 0 0.9rem;
  }

  h2:not(:first-child),
  h3:not(:first-child),
  h4:not(:first-child),
  h5:not(:first-child),
  h6:not(:first-child) {
    margin-top: 2rem;
  }

  p {
    margin-bottom: 1rem;
  }

  ul,
  ol {
    margin-bottom: 1rem;
    padding-left: 2em;
  }

  ul {
    list-style: disc;
  }
  li {
    display: list-item;
    margin-bottom: 1rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  a {
    color: #0071b9;
    &:hover {
      text-decoration: underline;
    }
  }

  blockquote {
    font-weight: 600;
    text-align: right;
    color: #9d9185;
    border-right: 4px solid #9d9185;
    margin: 40px 0;
    padding-right: 20px;

    @media screen and (max-width: 767px) {
      font-size: 18px;
    }
    @media screen and (min-width: 768px) {
      font-size: 23px;
      padding-left: 200px;
    }

    @media screen and (min-width: 1500px) {
      margin-right: -20px;
    }
  }

  .preamble {
    font-weight: 500;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 20px;
    }
    @media screen and (min-width: 768px) {
      font-size: 18px;
      margin-bottom: 45px;
    }
  }
}
