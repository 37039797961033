.b-store-block {
  &__wrapper {
    display: block;
    position: relative;
  }

  &__text {
    padding: 10px 20px;
    text-align: center;

    &.hasImage {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      color: white;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 500;
    hyphens: auto;
    margin-top: 32px;
    margin-bottom: 16px;

    @media screen and (min-width: 992px) {
      font-size: 48px;
    }
  }

  &__subtitle {
    font-size: 15px;
    margin-bottom: 32px;

    @media screen and (min-width: 992px) {
      font-size: 18px;
    }
  }

  &__cta {
    margin-bottom: 32px;
    a {
      font-size: 15px;
    }
  }
}
