@import '../../styles/utilities';


.c-catering-details {
  &__info-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    align-items: flex-end;
    background-color: #fff;
    padding: 20px;
  }

  &__inner {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__image {
    height: 300px;
    width: 50%;

    @media screen and (max-width: 992px) {
      width: 100%;
      height: 150px;
    }
  }



  &__details-box {
    padding: 20px;
  }

  &__contents {
    margin-bottom: 10px;
  }

  &__content-information {
    margin-bottom: 10px;
  }

  &__freshnessGuarantee-and-storageInformation {
    margin-bottom: 10px;
  }

  &__lower-section {
    height: 100%;
  }

  &__price-section {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__contents-list {
    margin: 0;
  }

  &__contents-list-item {
    margin-bottom: 0;
    font-size: 14px;
  }

  &__freshnessGuarantee-and-storageInformation,
  &__allergen-information,
  &__content-information,
  &__other-information,
  &__ingredient-information {
    p {
      font-size: 14px;
    }
  }

}

.availability {
  margin-top: 10px;
}