.b-product-carousel {
  max-width: 100%;
  width: 100%;

  .slick__pagination-wrapper{
    position:absolute;
    bottom: -10px;
    left: 0;
    right: 0;
  }
  .slick__pagination-container {
    max-width: 100px;
    margin: 0 auto;
    height: 20px;
    position: relative;
    overflow:hidden;
    display:flex;
    justify-content:center;
  }
  .slick__pagination{
    flex-wrap: nowrap;
    display:flex;
    position:absolute;
    left:0;
    right: unset;
    padding: 0;
   transition: left 300ms ; 
   >li{
    
    margin:0;
    width: 14px;
    height: 14px;

    .slick__dot-container{
      display:grid;
      place-content: center;
      width: 100%;
      height: 100%;
    }
    .slick__dot {
      height: 6px;
      width: 6px;
      border-radius: 100%;
      display:block;
      transform-origin: center;
      translate: scale 300ms ;
      background: #a7a7a7;
    }
   }
  
  }
  .slick-disabled {
    > button {
      background-color: #E5E4E5;
    }
   }
  .slick-list {
    padding-left:0;
    overflow:visible !important;
    .slick-track{
      display:flex;
      gap: 0px;
      .slick-slide{
        height: unset;
        > div {
          height: 100%;
          // can't style this with classnames for some reason
          > div {
            height: 100%;
          }
        }
        .slick-prod-card{
          height: 100%;
        }
      }
    }
  }
  .slick-dots {
    bottom: -10px;
  }
  .slick-next, .slick-prev {
    height: unset;
    z-index:1;
    top:65%;

    &::before {
      display:none;
    }
  }
  .slick-prev{
    left:-8px
  }
  .slick-next{
    right:-8px;
  }
 
}
.b-recipe-carousel {
  .slick-next, .slick-prev {
    top: 240px;
    @media (max-width: 768px){
      top: 160px;

    }
  }

}