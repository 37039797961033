.b-recipeblock {
  overflow: hidden;
  &__carousel-card{
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 991px) {
    padding: 0 10px;

    &--no-pad {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0 20px;

    &--no-pad {
      padding-left: 0;
      padding-right: 0;
    }
  }

  h2 {
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
    @media screen and (min-width: 768px) {
      font-size: 46px;
    }
  }

  &__moreprods {
    text-align: right;
    margin-bottom: 20px;

    a {
      font-size: 19px;
      font-weight: 500;
      text-decoration: underline;
      color: inherit;
    }
  }

  &__card {
    display: flex;
    width: 50%;

    @media screen and (min-width: 1100px) {
      width: calc(100% / 3);
    }

    @media screen and (min-width: 1300px) {
      width: calc(100% / 4);
    }

    @media screen and (min-width: 1530px) {
      width: calc(100% / 5);
    }
  }

  // Template specific workarounds
  $root: &;
  &.pagelayoutsidebar {
    #{$root}__card {

      @media screen and (min-width: 1100px) {
        width: calc(100% / 3);
      }

      @media screen and (min-width: 1530px) {
        width: calc(100% / 4);
      }
    }
  }
}
