@import '../../../styles/variables';

.c-producttable-product {
  margin-bottom: 4px;
  font-size: $font-size-sm;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: inherit;
  transition: height 0.2s ease-in-out;
  border-bottom: 1px solid $color-black-lighter;
  padding: 5px 0 5px;

  &:last-of-type {
    border-bottom: none;
  }

  &__row {
    width: 100%;
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  &__link-wrapper {
    display: block;
    align-items: center;
    width: 100%;
  }

  &__title {
    word-wrap: break-word;
    .name {
      font-size: $font-size-sm;
      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-base;
      }
      line-height: 1.3;
      margin-top: 3px;
    }
    .description {
      font-size: $font-size-xxs;
      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-xsm;
      }
    }
  }

  &__line-price {
    white-space: nowrap;
  }
  &__image {
    height: 5rem;
    padding: map-get($spacers, 1);
  }

  &__row .dropdown-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  &__qty {
    width: 140px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left:8px;
    .buy-button {
      display: flex;
      justify-content: flex-end;
      padding-right: map-get($spacers, 1);
      position: relative;
      height: map-get($spacers, 6);
      align-items: baseline;
      
      @media screen and (min-width: $screen-md-min) {
        height: map-get($spacers, 1);
      }
      .c-cmdbtn {
        position: absolute;
        right: 0;
        width: 130px;
        @media screen and (min-width: $screen-md-min) {
          top: -15px;
        }
      }
      .changeqty {
        position: absolute;
        right: 0;
        width: 130px;
        @media screen and (min-width: $screen-md-min) {
          top: -15px;
        }
      }
    }
  }
}
