@import '../../styles/variables';

.offer-card-container {
  background-color: $color-white;
  box-shadow: 0 1px 0 0 #d7d7d7;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  justify-content: space-between;
  padding: 8px;
  text-align: center;
  transition: box-shadow 0.3s ease;

  &:hover {
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.1);
    color: $color-black;
  }

  .offer-card-upper {
    display: flex;
    flex-direction: column;

    .image-container {
      display: block;
      margin-top: 2px;
      position: relative;

      .image {
        height: 164px;
        margin: auto;
        max-width: 164px;
      }

      .price-stripe-container {
        position: absolute;
        top: 0;
      }
    }

    .price-tag-container {
      display: flex;
      justify-content: center;
      height: 70px;
      margin-bottom: 12px;
      margin-top: -35px;
    }

    h3 {
      margin: 0;
    }
  }

  .offer-card-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;
    padding: 0 4px 4px;
  }
}
