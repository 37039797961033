.c-droparrow {
  display: inline-block;
  width: 10px;
  height: 8px;
  position: relative;
  vertical-align: middle;

  &:before,
  &:after {
    display: block; content: "";
    position: absolute;
    width: 7px;
    height: 2px;
    left: 2px;
    top: 3px;
    background: #333;
    border-radius: 1px;
    transition: ease-in-out .2s;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
    left: 6px;
  }

  &.up {
    &:before {
      transform: rotate(-45deg);
    }
  }

  &.up {
    &:after {
      transform: rotate(45deg);
    }
  }

  &.left {
    transform: rotate(90deg);
  }

  &.right {
    transform: rotate(-90deg);
  }

  &.small {
    width: 8px; height: 5px;
    &:before,
    &:after {
      width: 5px;
    }

    &:after {
      left: 3px;
    }
  }

  &.clickable {
    padding: 16px;

    &:before,
    &:after {
      left: 8px;
      top: 18px;
    }
    &:after {
      left: 14px;
    }
  }
}
