.b-delivery-district {
  &__picture {}
  &__title {}

  &__text {}

  &__zipcodes {
    margin: 20px 0 40px;
  }

  &__city-name {
    text-transform: capitalize;
  }
}
