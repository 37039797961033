.hazard {
  display: flex;
  align-items: center;
  gap: 10px;
}

.signal-word {
  margin-top: 3px;
  font-weight: 500;
}
