@import '../../../styles/colors';

.cart-summary {
	position:relative;
	position: absolute;
}

.cart-summary-overlay {
	position:absolute;
	top: 0;
	bottom:0;
	left:0;
	right:0;
	width: 100%;
}

.summary {
	background: #fff;
	padding: 0px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.06);
	border-radius: 8px 8px 0 0;
	padding: 16px 16px;
  
	.charges{
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
	.label{
		display: flex;
		align-items: center;
		gap:8px;
	
	}
}
.summary__no-shadow{
	box-shadow: none;

}
