.c-sticker {
  display: flex;
  position: absolute;
  &--column {
    flex-direction: column;
    & > img {
      margin: 5px 0;
      &:last-child {
        margin-bottom: 0;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &--row {
    flex-direction: row;
    & > img {
      margin: 0 5px;
      &:last-child {
        margin-left: 0;
      }

      &:first-child {
        margin-right: 0;
      }
    }
  }
}
