.c-stdcheckbox {
  align-items: center;
  display: flex;
  cursor: pointer;
  font-size: 14px;

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .invalid & {
    color: #e4777a;

    a {
      color: inherit;
    }
  }

  a {
    text-decoration: underline;
    cursor: pointer;
  }

  input {
    display: none;

    &:hover + i {
      border-color: #0071b7;
      transition: border-color 0.1s ease;
    }

    &:checked + i {
      background-color: #0071b7;
      border-color: transparent;
    }

    &:checked + i::after {
      content: '';
      position: absolute;
      left: 5px;
      top: 1px;
      width: 6px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  i {
    position: relative;
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    margin-right: 7px;
    border: 2px solid;
    border-color: #c4c4c4;
    border-radius: 2px;
    transition: border-color 0.1s ease;
  }
}
