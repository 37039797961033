@import '../../styles/variables';
.p-carousel_card {
  height: 100%;
}

.productcard-container {
  background-color: #fff;
  box-shadow: 0 1px 0 0 #d7d7d7;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8px;
  transition: box-shadow 0.3s ease;

  &:hover {
    color: #333;
    box-shadow: 0 0 6px 4px rgba(0, 0, 0, 0.1);
  }

  .product-card {
    &__upper-container {
      .top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .end {
          display: flex;
          align-items: end;
          flex-direction: column;
          font-size: 9px;
        }
      }

      .image-container {
        display: block;
        position: relative;

        .product-image {
          height: 164px;
          margin: auto;
          max-width: 164px;
        }

        .price-stripe-container {
          position: absolute;
          top: 0;
        }

        .markings {
          display: flex;
          flex-direction: column;
          gap: 2px;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: 0;

          img {
            height: auto;
            width: 20px;
          }
        }
      }

      .price-tag-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 70px;
        margin-top: -35px;
      }
    }

    &__lower-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 12px;
      justify-content: center;
      height: 100%;
      margin-top: 12px;
      text-align: center;
      word-break: break-word;
      padding: 0 4px 4px;

      h3 {
        margin: 0;
      }

      .price-details-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin-bottom: 12px;

        p {
          text-align: center;
        }
      }

      .max-amount {
        font-size: 10px;
        color: #e02721;
        transition: 0.3s;
      }

      .cartactions {
        position: relative;
        width: 100%;
        display: block;
        height: 31px;

        .buy-button {
          position: absolute;
          top: 0;
          width: 100%;

          .changeqty,
          .addtocart {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
          }

          .changeqty {
            justify-content: space-between;
            width: 100%;
            max-width: 140px;
            margin: auto;
          }

          .c-cmdbtn {
            display: flex;
            justify-content: center;
            line-height: 31px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
