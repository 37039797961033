@import '../../styles/_utilities.scss';
.c-carousel-card{
  height: 100%;
}
.c-catering-card {
  background-color: #ffffff;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;

  &__image {
    height: 200px;
  }

  &__title {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }

  &__description {
    & > p {
      font-size: 14px;
    }
  }

  &__sides {
    margin-bottom: 15px;
    margin-top: auto;
  }

  &__lower-section {
    margin-top: auto;
    align-items: flex-start;
  }

  &__lower-section-with-sides {
    margin-top: unset;
  }

  &__error-message {
    display: flex;
    justify-content: flex-end;

    & > p {
      color: #ef0000;
      font-size: 12px;
      text-align: right;
    }
  }

  &__amount-input {
    border: 1px solid #e7e7e7;
    border-radius: 2px;
    box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-size: 12px;
    font-weight: 500;
    height: 32px;
    text-align: center;
    width: 50px;
  }

  &__portions {
    font-size: 12px;
    font-weight: 500;
  }

  &__price-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    flex-wrap:wrap;

    p {
      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  &__select-amount {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
  }
}