//
//  COLORS - Primary
//
$color-transparent: rgba(0, 0, 0, 0);
$color-yellow-dark: rgb(254, 222, 83); // #fede53
$color-yellow: rgb(254, 229, 35); // #fee523
$color-yellow-light: rgb(255, 239, 122); // #ffef7a
$color-yellow-lighter: rgb(255, 246, 178); // #fff6b2
$color-yellow-lighterer: rgb(255, 250, 226); // #fffae2
$color-yellow-lightest: rgb(255, 251, 231); // #fffbe7
$color-tacha: #d8c776;

$color-black: rgb(51, 51, 51); // #333333
$color-black-light: rgb(167, 167, 167); // #a7a7a7
$color-black-lighter: rgb(231, 231, 231); // #e7e7e7
$color-black-lightest: rgb(247, 247, 247); // #f7f7f7
$color-black-transparent: rgba(51, 51, 51, 0.302);

$color-grey: rgb(199, 199, 199); // #c7c7c7
$color-white: rgb(255, 255, 255); // #ffffff

$color-blue-darker: rgb(0, 62, 102); // #003e66
$color-blue-dark: rgb(0, 91, 147); // #005b93
$color-blue: rgb(0, 113, 183); // #0071b7
$color-blue-light: rgb(56, 156, 218); // #389cda
$color-blue-lighter: rgb(194, 221, 238); // #c2ddee
$color-blue-lightest: rgb(232, 243, 252); // #e8f3fc

$color-red: rgb(225, 38, 33); // #e12621
$color-red-lightest: rgb(249, 220, 219); // #f9dcdb

//
//  COLORS - Secondary
//

$color-eko: rgb(81, 176, 71); //#51B047
$color-eko-lightest: rgb(237, 247, 237); //#edf7ed
$color-lactose-free: rgb(91, 197, 242); //#5BC5F2
$color-gluten-free: rgb(199, 79, 7); //#C74F07
$color-vegetarian-free: rgb(0, 191, 76); //#XXX
$color-vegan-free: rgb(7, 199, 84); //#XXX

$color-news: rgb(166, 21, 128); //#a61580
$color-news-lightest: rgb(246, 232, 242); //#f6e8f2
$color-extra: rgb(89, 196, 241); //#59c4f1

//
//  COLORS - Accent
//

$color-ccd: rgb(193, 74, 4); // #c14a04
$color-cheese: $color-ccd;
$color-charcuterie: $color-ccd;
$color-dairy: $color-ccd;
$color-fg: rgb(35, 112, 57); //#237039
$color-fruit: $color-fg;
$color-greens: $color-fg;
$color-bakery: rgb(118, 75, 39); //#764b27
$color-extra-background: rgb(173, 157, 157); //#ad9d9d
$color-background: rgb(212, 191, 169); //#d4bfa9
$color-ff: rgb(31, 134, 157); //#1f869d
$color-freezer: $color-ff;
$color-fish: $color-ff;
$color-meat: rgb(162, 61, 76); //#a23d4c
$color-other: rgb(105, 93, 88); //#695d58

//
//  COLORS - links
//

$link-color: $color-black;
$link-decoration: underline;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

//
//  COLORS - buttons
//
$color-button-disabled-text: rgb(51, 51, 51); // #333333

$color-button-primary-static: rgb(255, 229, 34); // #ffe522
$color-button-primary-hover: rgb(255, 239, 122); // #ffef7a
$color-button-primary-active: rgb(255, 215, 15); // #ffd70f
$color-button-primary-disabled: rgba(254, 219, 76, 0.302); // #fedb4c4d
$color-button-primary-shadow: rgb(235, 199, 40); // #ebc728
$color-button-primary-border: rgba(51, 51, 51, 0.051); // #3333330d

$color-button-grey-static: rgb(232, 232, 232); // #e8e8e8
$color-button-grey-hover: $color-button-primary-hover;
$color-button-grey-active: rgb(214, 214, 214); //#d6d6d6
$color-button-grey-disabled: rgb(245, 245, 245); //#f5f5f5
$color-button-grey-disabled-text: rgb(206, 206, 206); //#cecece
$color-button-grey-shadow: rgb(198, 198, 198); //#c6c6c6
$color-button-grey-disabled-accent: rgba(51, 51, 51, 0.051); //#3333330d

$color-button-white-static: rgb(255, 255, 255); //#ffffff
$color-button-white-hover: $color-button-primary-hover;
$color-button-white-active: rgb(247, 247, 247); //#f7f7f7
$color-button-white-disabled: rgba(255, 255, 255, 0.6); //#ffffff99
$color-button-white-static-shadow: rgb(232, 232, 232); //#e8e8e8
$color-button-white-disabled-border: rgba(51, 51, 51, 0.051); //#3333330d

$color-button-tertiary: rgb(255, 255, 255); //#ffffff
$color-button-tertiary-static-border: rgb(165, 165, 165); //#a5a5a5
$color-button-tertiary-hover-border: rgb(204, 204, 204); //#cccccc
$color-button-tertiary-disabled-border: rgb(232, 232, 232); //#e8e8e8
$color-button-tertiary-shadow: rgba(0, 0, 0, 0.102); //#0000001a

$color-button-prio: rgb(168, 24, 127); // #A8187F
$color-button-prio-hover: rgba(168, 24, 127, 0.74); // #a8187fbd
$color-button-prio-active: rgba(168, 24, 127, 0.74); // #a8187fbd
$color-button-prio-disabled: rgba(254, 219, 76, 0.302); // #fedb4c4d
$color-button-prio-shadow: rgb(168, 24, 127); // #A8187F
$color-button-prio-border: rgba(51, 51, 51, 0.051); // #3333330d

$color-button-blue: $color-blue; // #A8187F
$color-button-blue-hover: $color-blue-light; // #a8187fbd
$color-button-blue-active: $color-blue; // #a8187fbd
$color-button-blue-disabled: rgba(254, 219, 76, 0.302); // #fedb4c4d
$color-button-blue-shadow: $color-blue; // #A8187F
$color-button-blue-border: $color-blue; // #3333330d

$color-button-misc-static-white: rgb(255, 255, 255); //#ffffff
$color-button-misc-static: rgb(232, 232, 232); //#e8e8e8
$color-button-misc-hover: rgb(255, 232, 117); //#ffe875
$color-button-misc-active: rgb(255, 215, 15); //#ffd70f
$color-button-misc-disabled: rgba(232, 232, 232, 0.251); //#e8e8e840
$color-button-misc-on: rgb(255, 215, 15); //#ffd70f

$color-button-blue-static: $color-blue;
$color-button-blue-hover: $color-blue-light;
$color-button-blue-active: $color-blue-dark;

$color-button-success-static: rgb(70, 160, 63); // #46a03f
$color-button-success-hover: rgb(107, 160, 104); // #6ba068
$color-button-success-shadow: rgb(63, 139, 57); // #3f8b39
$color-button-success-disabled: rgba(107, 160, 104, 0.282); // #6ba06848
