@import '../breakpoints';

// grid
[#{$attributeName}~='#{$gridName}'],
[#{$attributeName}*='#{$gridName}-'] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0 (-$gutter/2);
}
// columns
[#{$attributeName}~='#{$colName}'],
[#{$attributeName}*='#{$colName}-'],
[#{$attributeName}*='#{$colName}_'] {
  box-sizing: border-box;
  padding: 0 ($gutter/2) $gutter-vertical;
  max-width: 100%;
}

// Handle just l-column and l-column_
[#{$attributeName}~='#{$colName}'],
[#{$attributeName}*='#{$colName}_'] {
  flex: 1 1 0%;
}

// handle l-column-
[#{$attributeName}*='#{$colName}-'] {
  flex: none;
}

// column and grid combinations
[#{$attributeName}~='#{$gridName}'][#{$attributeName}~='#{$colName}'],
[#{$attributeName}~='#{$gridName}'][#{$attributeName}*='#{$colName}-'],
[#{$attributeName}~='#{$gridName}'][#{$attributeName}*='#{$colName}_'],
[#{$attributeName}*='#{$gridName}-'][#{$attributeName}~='#{$colName}'],
[#{$attributeName}*='#{$gridName}-'][#{$attributeName}*='#{$colName}-'],
[#{$attributeName}*='#{$gridName}-'][#{$attributeName}*='#{$colName}_'],
[#{$attributeName}*='#{$gridName}_'][#{$attributeName}~='#{$colName}'],
[#{$attributeName}*='#{$gridName}_'][#{$attributeName}*='#{$colName}-'],
[#{$attributeName}*='#{$gridName}_'][#{$attributeName}*='#{$colName}_'] {
  margin: 0;
  padding: 0;
}

// Add suffix

// For the grid
[#{$attributeName}*='#{$gridName}-'] {
  // removed spacing between cols : noGutter.
  &[#{$attributeName}*='-noGutter'] {
    margin: 0;
    > [#{$attributeName}~='#{$colName}'],
    > [#{$attributeName}*='#{$colName}-'] {
      padding: 0;
    }
  }

  // In the new deisgn we want a small gutter.
  // Maybe could be a better way of doing this with mixins.
  // For nwo it's fine.
  @media screen and (max-width: $screen-phone) {
    &[#{$attributeName}*='-mobileGutter'] {
      margin: 2px;
      > [#{$attributeName}~='#{$colName}'],
      > [#{$attributeName}*='#{$colName}-'] {
        padding: 2px;
      }
    }
  }

  // Some nice to have wrappers

  // Horizontal alignment on center
  &[#{$attributeName}*='-center'] {
    justify-content: center;
  }

  // Horizontal alignment on right
  &[#{$attributeName}*='-right'] {
    justify-content: flex-end;
    align-self: flex-end;
    margin-left: auto;
  }

  // Vertical alignment on top
  &[#{$attributeName}*='-top'] {
    align-items: flex-start;
  }

  // Vertical alignment on middle
  &[#{$attributeName}*='-middle'] {
    align-items: center;
  }

  // Vertical alignment on bottom
  &[#{$attributeName}*='-bottom'] {
    align-items: flex-end;
  }

  &[#{$attributeName}*='-column'] {
    flex-direction: column;
    > [#{$attributeName}*='#{$colName}-'] {
      flex-basis: auto;
    }
  }

  // Spaces between and around cols
  &[#{$attributeName}*='-spaceBetween'] {
    justify-content: space-between;
  }

  &[#{$attributeName}*='-spaceAround'] {
    justify-content: space-around;
  }

  // Equal heights columns
  &[#{$attributeName}*='-equalHeight'] > [#{$attributeName}~='#{$colName}'],
  &[#{$attributeName}*='-equalHeight'] > [#{$attributeName}*='#{$colName}-'],
  &[#{$attributeName}*='-equalHeight'] > [#{$attributeName}*='#{$colName}_'] {
    align-self: stretch;
    > * {
      height: 100%;
    }
  }
}

// For cols
[#{$attributeName}*='#{$colName}-'] {
  &[#{$attributeName}*='-top'] {
    align-self: flex-start;
  }

  &[#{$attributeName}*='-middle'] {
    align-self: center;
  }

  &[#{$attributeName}*='-bottom'] {
    align-self: flex-end;
  }
}

// Set grid by a number. E.g grid_3
@include makeGridByNumber(#{$gridName});
@each $mq-key, $mq-value in $mq-list {
  @include bp(#{$mq-key}) {
    @include makeGridByNumber(_#{$mq-key});
  }
}

// Diffrent col sizes
@include makeCol(#{$colName});
@include makeOff(off); //Offset

@each $mq-key, $mq-value in $mq-list {
  @include bp(#{$mq-key}) {
    @include makeCol(_#{$mq-key});
    @include makeOff(_#{$mq-key}); //Offset
    @include makeFirstLast(_#{$mq-key});
  }
}

// Just a helper to hide an element with a specific breakpoint. Hide element in react at first.
// Added this to maybe fix legacy stuff on :-). Can be removed later on.
@each $mq-key, $mq-value in $mq-list {
  @include bp(#{$mq-key}) {
    [#{$attributeName}*='#{$mq-key}-hidden'] {
      display: none;
    }
  }
}
