.cart-coupons {
  padding-top: 16px;
  border-top: 1px solid #E8E8E8;
  background: #f7f7f7;
  &__wrapper {
    background:white;
    padding: 24px 16px;

  }
}
