.c-cartbtn {
  background-image: url("../../assets/cart_rounded.svg");
  background-size: 32px 32px;
  background-position: 0px 0px;
  box-shadow: none;

  &:hover{
    box-shadow: none;
  }
}
