.c-openinghours {
  h4 {
    display: flex;
    font-weight: 500;
    justify-content: space-between;

    &:first-child {
      margin-top: 0;
    }
  }

  &__holiday {
    span {
      &:last-child {
        color: #e22b2b;
      }
    }
  }

  &__foldout {
    margin-top: 20px;
  }
}
