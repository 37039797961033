@import '../../styles/breakpoints';

.leaflets-multiple {
  display: flex;
  gap: 12px;

  @media screen and (max-width: 1800px) {
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    & > * {
      flex: 0 0 80%;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (min-width: 1800px) {
    gap: 24px;

    & > * {
      flex: 1;
    }
  }
}
