.c-productdetails {
  h2 {
    margin-bottom: 3px;
  }

  &__texts {
    white-space: pre-line;
    font-size: 14px;

    @media screen and (min-width: 768px) {
      clear: both;
      padding: 20px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
      padding: 10px;
    }
  }

  &__related {
    clear: both;

    @media screen and (min-width: 768px) {
      padding: 0 20px;
    }

    @media screen and (max-width: 767px) {
      .c-grid {
        &.cards {
          margin: 0;
        }
      }
    }
  }

  .card {
    @media screen and (min-width: 544px) {
      width: calc(100% / 2);
    }
    @media screen and (min-width: 768px) {
      width: calc(100% / 4);
    }
  }
}
