@import '../../styles/_variables.scss';

.c-stdselect {
  position: relative;
  display: inline-block;
  z-index: 0;
  background: #fff;
  border-radius: 4px;
  border-right: 30px solid #e7e7e7;
  cursor: pointer;
  width: 100%;
  height: 31px;


  &--highlight{
    box-shadow: 0 0 2px 1px $color-red;
  }
  &--fullwidth  {
    width: 100%;
  }
   

  select {
    border: 1;
    border-radius: 2px;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-size: 12px;
    background-color: transparent;
    appearance: none;
    width: calc(100% + 30px);
    height: 31px;
    padding: 0 30px 0 11px;
    position: relative;
    z-index: 1;
  }

  .c-droparrow {
    position: absolute;
    right: -17px;
    top: 12px;
  }
}
