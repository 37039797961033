.b-header .servicelayer {
  position: absolute;
  top: 60px;
  width: 100%;
  background: #f7f7f7;
  padding: 30px 0;
  transition: ease-in-out 0.2s;
  visibility: visible;
  opacity: 1;
}
.b-header .servicelayer:not(.active) {
  visibility: hidden;
  opacity: 0;
}
.b-header .servicelayer .b-container {
  position: relative;
  padding: 0 10px;
}
.b-header .servicelayer .c-cross {
  position: absolute;
  right: 10px;
  top: 0;
}
.b-header .servicelayer .header {
  margin-bottom: 10px;
}
.b-header .servicelayer .title {
  font-size: 28px;
  font-weight: 600;
  color: #054066;
}
.b-header .servicelayer .titlebtns {
  float: right;
  margin-right: 70px;
}
.b-header .servicelayer .titlebtns .c-cmdbtn {
  margin-left: 17px;
}
.b-header .servicelayer .columns {
  display: flex;
  display: -webkit-flex;
}
.b-header .servicelayer .column,
.b-header .servicelayer .nocolumns {
  background: white;
  min-height: 300px;
  border-radius: 2px;
  padding: 26px 18px 0;
  font-size: 14px;
}
.b-header .servicelayer .column {
  flex: 1;
  -webkit-flex: 1;
}
.b-header .servicelayer .column:not(:last-child) {
  margin-right: 20px;
}
.b-header .servicelayer .subtitle {
  color: #054066;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.b-header .servicelayer .c-richtext ul {
  padding-left: 0;
}
.b-header .servicelayer .c-richtext li {
  margin-bottom: 0px;
  list-style: none;
}
.b-header .servicelayer .c-richtext img {
  vertical-align: middle;
}
.b-header .servicelayer .buttons .c-cmdbtn {
  width: 100%;
  margin-bottom: 20px;
}
.b-header .servicelayer .nocolumns {
  padding-bottom: 10px;
}
.b-header .servicelayer .multicolumn {
  column-count: 6;
  -webkit-column-count: 6;
}
.b-header .servicelayer .multicolumn li {
  margin-bottom: 10px;
}
.b-header .servicelayer .findstore {
  position: relative;
  margin-bottom: 35px;
}
.b-header .servicelayer .findstore .searchicon {
  position: absolute;
  left: 15px;
  top: 12px;
}
.b-header .servicelayer .findstore input {
  height: 42px;
  line-height: 42px;
  padding-left: 40px;
  width: 350px;
  font-size: 14px;
  border: solid 1px rgba(51, 51, 51, 0.1);
  border-radius: 2px;
  margin-right: 5px;
}
@media screen and (max-width: 767px) {
  .b-header .servicelayer .findstore input {
    font-size: 16px;
  }
}
.b-header .servicelayer .findstore .c-cmdbtn {
  height: 41px;
  line-height: 41px;
}
