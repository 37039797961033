@import '../../../styles/variables';

.c-suggestions {
  &__title {
    h3 {
      font-size: $font-size-base;
      margin-top: 0;
      margin-bottom: map-get($spacers, 3);
      @media screen and (min-width: $screen-md-min) {
        margin-bottom: map-get($spacers, 2);
        font-size: $font-size-sm;
      }
    }
    &--count {
      color: rgb(123, 123, 123);
      font-weight: $font-weight-base;
      font-size: $font-size-xsm;
    }
  }
  &__children {
    margin: 0 -10px;
    @media screen and (min-width: $screen-md-min) {
      margin: 0;
    }
  }
  &__link {
    pointer-events: none;
  }
}
