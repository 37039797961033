.c-pagetop .mast { background: white; }
.c-pagetop .mast .breadcrumbs,
.c-pagetop .mast h1 { font-size: 16px; font-weight: 500; line-height: 1.3; padding: 9px 0 8px; }
.c-pagetop .mast .breadcrumbs a:hover { text-decoration: underline; }
.c-pagetop .mast h1 { display: inline-block; margin: 0; }
.c-pagetop .head img { width: 100%; height: auto; display: block; }
.c-pagetop .sort { display: flex; display: -webkit-flex; }
.c-pagetop .sort .label { font-weight: 500; margin-top: 5px; margin-right: 8px; }
@media screen and (max-width: 991px) {
	.c-pagetop { margin-bottom: 10px; }
	.c-pagetop .mast { padding: 10px; } //margin-top: 10px; }
	.c-pagetop .mast .breadcrumbs,
	.c-pagetop .mast h1 { font-size: 12px; padding: 5px 0 4px; }
	.c-pagetop .head img { margin: 10px 0; }
	.c-pagetop .head .title { font-size: 26px; font-weight: 600; }
	.c-pagetop .head .subtitle { font-size: 14px; }
	.c-pagetop .sort { margin-top: 20px; }
	.c-pagetop .sort .label { font-size: 12px; }
	.c-pagetop .sort .select { flex: 1; -webkit-flex: 1; }
	.c-pagetop .sort .c-stdselect { width: 100%; }
	.c-pagetop .c-selectedfilters { background: white; padding: 10px 10px 5px; }

	.c-pagetop { display: flex; display: -webkit-flex; flex-direction: column; -webkit-flex-direction: column; }
	.c-pagetop .mast { order: 2; -webkit-order: 2; }
	.c-pagetop .head { order: 1; -webkit-order: 1; }
	.c-pagetop .c-selectedfilters { order: 3; -webkit-order: 3; }
}
@media screen and (min-width: 992px) {
	// .c-pagetop { padding-top: 20px; }
	.c-pagetop .mast { padding: 8px 14px; margin-bottom: 20px; }
	.c-pagetop .head { position: relative; margin-bottom: 20px; }
	.c-pagetop .head .text { background: rgba(120, 120, 120, .75); color: white; padding: 20px 40px 20px 20px; position: absolute; bottom: 20px; max-width: 583px; }
	.c-pagetop .head .title { font-size: 33px; font-weight: 600; }
	.c-pagetop .sort { float: right; padding-top: 3px; }
	.c-pagetop .sort .label { font-size: 14px; }
	.c-pagetop .c-selectedfilters { margin-bottom: 15px; }
}
