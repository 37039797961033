.pnlcontent .c-breadcrumbs {
  margin-left: 20px;
  margin-right: 20px;
}
.c-breadcrumbs {
  display: block;
  background: #fff;
  padding: 15px 20px;
  padding-bottom: 13px;
  margin-bottom: 20px;
}
.c-breadcrumbs li {
  position: relative;
  display: inline-block;
}
.c-breadcrumbs li > span {
  white-space: nowrap;
}
.c-breadcrumbs li a > h1 {
	padding:inherit;
	font-size:inherit;
	line-height:inherit;
	font-weight:inherit;
  display:inherit;
  white-space: nowrap;
  margin: 0;
}

.c-breadcrumbs li:not(:last-child):hover span {
  text-decoration: underline;
}
.c-breadcrumbs li + li {
  margin-left: 20px;
}
.c-breadcrumbs li + li a span:before,
.c-breadcrumbs li + li a h1:before {
  content: ">";
  font-weight: 400;
  position: absolute;
  top: 1px;
  left: -15px;
}
.c-breadcrumbs li:last-child {
  font-weight: 600;
}
@media screen and (max-width: 991px) {
  .c-breadcrumbs {
    font-size: 12px;
    padding-bottom: 12px;
    margin-bottom: 0;
  }
}
