.c-critical-requests {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 10px 10px 10px;
  z-index: 2147483647; // Blame imbox for this
  max-width: 300px;
  max-height: calc(100vh - 10px - 63px);
  overflow-y: auto;
  font-size: .75em;
  box-shadow: 0 5px 12px rgba(0,0,0,.12);
  background-color: #f7f7f7;

  @media screen and (min-width: 500px) {
    width: calc(100% - 20px);
  }

  transition: transform .5s cubic-bezier(0.215, 0.610, 0.355, 1.000), opacity .5s ease;

  &--enter {
    display: block;
    transform: translate3d(310px, 0, 0);
    opacity: 0;
  }
  &--enter-active {
    display: block;
    opacity: 1;
    transform: translate3d(0px, 0, 0);
  }
  &--enter-done {
    display: block;
  }
  &--exit {
    display: block;
    transform: translate3d(310px, 0, 0);
    transition-delay: .3s;
    opacity: 0;
  }
  &--exit-done {
    display: none;
  }

  &--open {
    @media screen and (max-width: 500px) {
      margin-bottom: 63px;
    }
  }

  &__toggler {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    padding: 4px 18px 0;
    cursor: pointer;
  }

  &__head {
    padding: 15px 15px 15px 20px;
    border-top: 3px solid #ffe522;
    background-color: #fffae2;
    font-weight: 500;
  }

  &__head-title {
    font-weight: 800;
    padding-right: 35px;
    margin-bottom: 1em;

    >p+p {
      margin-top: 1em;
    }
  }

  &__head-closed {
    display: flex;
    align-items: center;
    padding-right: 35px;
  }

  &__warning {
    width: 18px;
    height: 18px;
    margin-right: 1em;
  }

  &__head-spinner {
    margin: 0 auto;
    padding: 0 1em;

    @media screen and (max-width: 500px) {
      display: none;
    }
  }

  &__items {
    display: block;
  }

  &__item {
    margin-bottom: 5px;
    padding: 5px 20px;
    background-color: #fff;
    border-bottom: 1px solid #ddd;

    &:first-child {
      margin-top: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    transition: transform .2s cubic-bezier(0.215, 0.610, 0.355, 1.000), opacity .2s ease;

    &--enter {
      display: block;
      transform: translate3d(310px, 0, 0);
      opacity: 0;
    }
    &--enter-active {
      display: block;
      opacity: 1;
      transform: translate3d(0px, 0, 0);
    }
    &--exit {
      transform: translate3d(310px, 0, 0);
      opacity: 0;
    }
    &--exit-done {
      display: none;
    }
  }

  &__item-inner {
    display: flex;
    align-items: center;
    min-height: 19px;
  }

  &__item-spinner {
    margin-left: auto;
  }

  &__item-failed {
    margin-left: auto;
    width: 18px;
    height: 18px;
  }
}
