/* Component: campaign products */
.b-productblock {
  display: inline-block;
  width: 100%;

  @media screen and (max-width: 991px) {
    padding: 0 10px;

    &--no-pad {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (min-width: 992px) {
    padding: 0 20px;

    &--no-pad {
      padding-left: 0;
      padding-right: 0;
    }
  }

  h2 {
    @media screen and (max-width: 767px) {
      font-size: 32px;
    }
    @media screen and (min-width: 768px) {
      font-size: 46px;
    }
  }

  &__moreprods {
    margin: 10px 0 20px;
    text-align: right;

    a {
      font-size: 19px;
      font-weight: 500;
      text-decoration: underline;
    }
  }
  &--carousel {
    overflow:hidden;
  }

  &__card {
    display: flex;
    width: 100%;

    @media screen and (min-width: 450px) {
      width: calc(100% / 2);
    }
    @media screen and (min-width: 544px) {
      width: calc(100% / 3);
    }
    @media screen and (min-width: 768px) {
      width: calc(100% / 4);
    }
    @media screen and (min-width: 1100px) {
      width: calc(100% / 5);
    }
    @media screen and (min-width: 1300px) {
      width: calc(100% / 6);
    }
    @media screen and (min-width: 1530px) {
      width: calc(100% / 7);
    }
    @media screen and (min-width: 1800px) {
      width: calc(100% / 8 - .1px); /* WORKAROUND: or Edge won't fit 6 cards probably because of rounding-issues */
    }
  }

  // Template specific workarounds
  $root: &;
  &.pagelayoutsidebar {
    #{$root}__card {
      @media screen and (min-width: 450px) {
        width: calc(100% / 2);
      }
      @media screen and (min-width: 544px) {
        width: calc(100% / 3);
      }
      @media screen and (min-width: 768px) {
        width: calc(100% / 4);
      }
      @media screen and (min-width: 1530px) {
        width: calc(100% / 5);
      }
      @media screen and (min-width: 1800px) {
        width: calc(100% / 6 - .1px); /* WORKAROUND: or Edge won't fit 6 cards probably because of rounding-issues */
      }
    }
  }
}