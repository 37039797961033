.card {
    display: flex;
    padding: 10px;
    &-carousel{
        width: 100% !important;
        height: 100%;
    }
    &.extraprice {
        .box-recipe {
            background: rgba(254, 223, 82, 0.25);
        }
    }
    &.cardprice {
        .box-recipe {
            background: #f6bdbc;
        }
    }
    &.card-prod {
        &.extraprice,
        &.isincart {
            .box-prod {
                border: 1px solid #fede53;
            }
        }
        &.cardprice {
            .box-prod {
                border: 1px solid #f6bdbc;
            }
        }
    }
    &.card-recipe {
        .box {
            float: left;
            border-style: solid;
            border-color: #e4e4e4;
        }
        .box-prod {
            width: calc(50% - 10px);
        }
        .box-recipe {
            width: calc(50% + 10px);
        }
        &.extraprice {
            .box {
                border-color: #fede53;
            }
        }
        &.cardprice {
            .box {
                border-color: #f6bdbc;
            }
        }
    }
    .box-prod {
        border: 1px solid #e4e4e4;
        border-radius: 2px;
    }
    @media screen and (max-width: 543px) {
      width: calc(100% / 2);
      &.double { width: calc(100% / 2 * 2); }
      &:nth-child(2n + 1) .c-fitrecipecard .arrow { display: none; }
    }
    @media screen and (min-width: 544px) and (max-width: 767px) {
      width: calc(100% / 3);
      &.double { width: calc(100% / 3 * 2); }
      &:nth-child(3n + 1) .c-fitrecipecard .arrow { display: none; }
    }
    @media screen and (min-width: 768px) and (max-width: 1529px) {
      width: calc(100% / 4);
      &.double { width: calc(100% / 4 * 2); }
      &:nth-child(4n + 1) .c-fitrecipecard .arrow { display: none; }
    }
    @media screen and (min-width: 1530px) and (max-width: 1799px) {
      width: calc(100% / 5);
      &.double { width: calc(100% / 5 * 2); }
      &:nth-child(5n + 1) .c-fitrecipecard .arrow { display: none; }
    }
    @media screen and (min-width: 1800px) {
      width: calc(100% / 6);
      &.double { width: calc(100% / 6 * 2); }
      &:nth-child(6n + 1) .c-fitrecipecard .arrow { display: none; }
    }
}
