@import '../../styles/_variables.scss';

.cart-header {
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  white-space: nowrap;

  &:hover {
    background-color: $color-button-primary-hover;

    @media (hover: none) {
      background-color: inherit;
    }
  }

  &.active {
    background-color: $color-button-primary-static;

    .badge {
      background: $color-black;
    }
  }


  .icon {
    img {
      // The handle of the shopping cart icon makes it look unbalanced when centered
      // so we push it upwards to the left
      transform: translate(-1px, -1px);
    }
  }

  @media screen and (min-width: 1360px) {
    min-width: 160px;
    padding: 13px 12px 0 12px;
  }

  @media screen and (max-width: 1359px) {
    justify-content: center;
  }

  @media screen and (min-width: 992px) {
    background-color: $color-button-primary-static; //#ffe522;
  }
}

.pulse-enter {
  animation: cart-pulse .6s infinite;

  &.active {
    animation-name: cart-pulse-active;
  }

  @media screen and (min-width: 992px) {
    animation-name: cart-pulse-active;
  }
}
.replacement-info {
  background-color:$color-blue-lighter;
  margin:10px;
  padding:10px;
  font-size:12px;
}
@keyframes cart-pulse { 50% { background: $color-yellow; } }
@keyframes cart-pulse-active { 50% { background: $color-white; } }
