@import '../../../styles/variables';

.c-recipe-details {
  font-size: $font-size-sm;

  @media screen and (min-width: $screen-md-min) {
    float: left;
    width: 250px;
    padding: 0 map-get($spacers, 5) map-get($spacers, 2) 0;
  }

  h3 {
    font-weight: 500;
    font-size: $font-size-base;
    color: #333;
    margin-bottom: 10px;
    margin-right: map-get($spacers, 3);
    @media screen and (min-width: $screen-md-min) {
      font-size: $h3-font-size;
      margin-bottom: 10px;
    }
  }
  .note {
    opacity: 0.5;
    font-size: 10px;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      clear: both;
      text-align: center;
    }
  }

  &__dropdown-wrapper {
    @media screen and (min-width: $screen-md-min) {
      display: block;
    }
  }

  &__ingredients {
    li {
      margin-bottom: 0;
    }
  }

  &__title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    h3 {
      font-size: $font-size-base;
      margin-top: 10px;
      @media screen and (min-width: $screen-md-min) {
        padding-left: 0;
        font-size: $h3-font-size;
        margin-top: 18px;
      }
    }
  }

  &__collapseindicator {
    position: absolute;
    right: 0;
    top: 0.2em;
    width: 14px;
    height: 14px;
  }
  &__collapseable {
    @media screen and (max-width: 1899px) {
      &:not(.active) {
        display: none;
      }
    }
  }
}
