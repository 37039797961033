/* Component: grid */
.c-grid {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -10px 10px;

  &.cards {
    margin: -10px;
    padding: 10px 0;
  }

  .card {
    display: flex;
    padding: 10px;
    width: 100%;

    & > * {
      width: 100%;
    }
  }

  @media screen and (max-width: 543px) {
    margin: -5px -5px 5px;

    .card {
      padding: 5px;
    }

    > .card {
      width: calc(100% / 2);
    }
  }
}
