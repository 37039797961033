.search-result__container {
    min-height: 100%;
    
    height: 100%;
    overflow-y: auto;
    overflow: -moz-scrollbars-none; 
    -ms-overflow-style: none; 
    background: #F7F7F7;

    &::-webkit-scrollbar {width: 0 !important ;}
    @media(min-width: 991px){
        overflow:scroll;
        min-height: unset;
    }
}

.search-result-overlay__container{
    display:flex;
    flex-direction: column;
    height: calc(100% - 72px);
    
}

.search-result__wrapper {
    background: #F7F7F7;
    height: auto;
    border-top: 1px solid rgba(51, 51, 51,0.5);

    @media(min-width: 991px){
        height: 100%;
        padding-bottom: 0px;
        overflow:auto;
    }
}

.search-mobile__overlay{
    flex: 1 0 auto;
    background: #F7F7F7;

    @media(min-width: 991px){
        display:none;
    }
}

