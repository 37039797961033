@import '../../../styles/variables';

.c-search-suggestions {
  display: flex;
  flex-direction: column;
  height: 100%;
  @media screen and (min-width: $screen-lgSmall) {
    flex-direction: row;
    max-height: 588px;
  }
  &__main-suggestions {
    @media screen and (min-width: $screen-md-min) {
      width: 100%;
      overflow-y: auto;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }
    &--has-sidebar {
      @media screen and (min-width: $screen-lgSmall) {
        width: 73%;
        padding-right: map-get($spacers, 5);
      }
    }
  }
  &__categorized-suggestions {
    &--is-sidebar {
      @media screen and (min-width: $screen-lgSmall) {
        width: 27%;
        overflow-y: auto;
        -ms-overflow-style: none;
        &::-webkit-scrollbar {
          width: 0 !important;
        }
      }
    }
    &--is-main-area {
      @media screen and (min-width: $screen-lgSmall) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-content: space-around;
        > div {
          width: 33%;
          margin-right: 20px;
        }
        > :last-of-type {
          margin-right: 0;
        }
      }
    }
  }

  &__categorized-items {
    font-size: $font-size-xsm;
  }
  &__categorized-item {
    margin-bottom: map-get($spacers, 3);
  }
}
