.u-mt-0,
.mt-0 {
  margin-top: 0;
}
.u-mt-5,
.mt-5 {
  margin-top: map-get($spacers, 1);
}
.u-mr-5 {
  margin-right: map-get($spacers, 1);
}
.u-mb-5,
.mb-5 {
  margin-bottom: map-get($spacers, 1);
}
.u-mt-10,
.mt-10 {
  margin-top: map-get($spacers, 2);
}
.u-mt-10,
.mt-10 {
  margin-top: map-get($spacers, 2);
}
.u-mb-10,
.mb-10 {
  margin-bottom: map-get($spacers, 2);
}
.u-mt-15 {
  margin-top: map-get($spacers, 3);
}
.u-mb-15 {
  margin-bottom: map-get($spacers, 3);
}
.u-mb-20,
.mb-20 {
  margin-bottom: map-get($spacers, 4);
}
.u-mt-20,
.mt-20 {
  margin-top: map-get($spacers, 4);
}

.u-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.u-my-10 {
  margin-top: map-get($spacers, 2);
  margin-bottom: map-get($spacers, 2);
}

.u-my-20 {
  margin-top: map-get($spacers, 4);
  margin-bottom: map-get($spacers, 4);
}

.u-p-0,
.p-0 {
  padding: 0;
}
.u-pt-0,
.pt-0 {
  padding-top: 0;
}
.u-pr-0,
.pr-0 {
  padding-right: 0;
}
.u-pb-0,
.pb-0 {
  padding-bottom: 0;
}
.u-pl-0,
.pl-0 {
  padding-left: 0;
}
.u-px-0,
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.u-py-0,
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.u-p-1,
.p-1 {
  padding: map-get($spacers, 1);
}
.u-pt-1,
.pt-1 {
  padding-top: map-get($spacers, 1);
}
.u-pr-1,
.pr-1 {
  padding-right: map-get($spacers, 1);
}
.u-pb-1,
.pb-1 {
  padding-bottom: map-get($spacers, 1);
}
.u-pl-1,
.pl-1 {
  padding-left: map-get($spacers, 1);
}
.u-py-1,
.py-1 {
  padding-top: map-get($spacers, 1);
  padding-bottom: map-get($spacers, 1);
}
.u-px-1,
.px-1 {
  padding-left: map-get($spacers, 1);
  padding-right: map-get($spacers, 1);
}
.u-p-2,
.p-2 {
  padding: map-get($spacers, 2);
}
.u-pt-2,
.pt-2 {
  padding-top: map-get($spacers, 2);
}
.u-pr-2,
.pr-2 {
  padding-right: map-get($spacers, 2);
}
.u-pb-2,
.pb-2 {
  padding-bottom: map-get($spacers, 2);
}
.u-pl-2,
.pl-2 {
  padding-left: map-get($spacers, 2);
}
.u-py-2,
.py-2 {
  padding-top: map-get($spacers, 2);
  padding-bottom: map-get($spacers, 2);
}
.u-px-2,
.px-2 {
  padding-left: map-get($spacers, 2);
  padding-right: map-get($spacers, 2);
}
.u-p-3,
.p-3 {
  padding: map-get($spacers, 3);
}
.u-pt-3,
.pt-3 {
  padding-top: map-get($spacers, 3);
}
.u-pr-3,
.pr-3 {
  padding-right: map-get($spacers, 3);
}
.u-pb-3,
.pb-3 {
  padding-bottom: map-get($spacers, 3);
}
.u-pl-3,
.pl-3 {
  padding-left: map-get($spacers, 3);
}
.u-py-3,
.py-3 {
  padding-top: map-get($spacers, 3);
  padding-bottom: map-get($spacers, 3);
}
.u-px-3,
.px-3 {
  padding-left: map-get($spacers, 3);
  padding-right: map-get($spacers, 3);
}
.u-p-4,
.p-4 {
  padding: map-get($spacers, 4);
}
.u-pt-4,
.pt-4 {
  padding-top: map-get($spacers, 4);
}
.u-pr-4,
.pr-4 {
  padding-right: map-get($spacers, 4);
}
.u-pb-4,
.pb-4 {
  padding-bottom: map-get($spacers, 4);
}
.u-pl-4,
.pl-4 {
  padding-left: map-get($spacers, 4);
}
.u-py-4,
.py-4 {
  padding-top: map-get($spacers, 4);
  padding-bottom: map-get($spacers, 4);
}
.u-px-4,
.px-4 {
  padding-left: map-get($spacers, 4);
  padding-right: map-get($spacers, 4);
}
