/* Fonts */
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local('Poppins Regular'), local('Poppins-Regular'),
    url('../fonts/poppins-400.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: local('Poppins Medium'), local('Poppins-Medium'),
    url('../fonts/poppins-500.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
    url('../fonts/poppins-600.woff2') format('woff2');
}
@font-face {
  font-family: 'Poppins';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('../fonts/poppins-700.woff2') format('woff2');
}
@font-face {
  font-family: 'Dom Casual';
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local('Dom Casual Std Bold'), local('DomCasualStd-Bold'),
    url('../fonts/dom-casual-bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Peekaboo';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local('Peekaboo'),
    url('../fonts/Peekaboo.woff2') format('woff2'),
    url('../fonts/Peekaboo.woff') format('woff'),
    url('../fonts/Peekaboo.ttf') format('truetype');
}
