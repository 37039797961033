@import '../../styles/variables';
.b-main {
  @media screen and (min-width: 992px) {
    width: calc(100% - 206px - 28px * 2);
    margin-left: calc(206px + 20px);
  }
  @media screen and (min-width: 1360px) {
    width: calc(100% - 220px - 300px - 22px * 2);
  }
  @media screen and (min-width: 1900px) {
    width: calc(100% - 206px - 300px - 18px * 2);
  }

  &.noticeHidden {

    @media screen and (max-width: 991px) {
      padding-top: 10px;
    }
  }

  &.no-nav {
    width: 100%;
    @media screen and (min-width: 992px) {
      margin: 0;
    }
  }
  .wideWithCart {
    max-width: 1860px;
    .b-main-inner {
      @media screen and (min-width: 1360px) {
        width: calc(100% - 300px - 20px);
      }
    }
  }
  &.no-cart {
    @media screen and (min-width: 1360px) {
      width: calc(100% - 206px - 20px * 2);
    }
  }
}

.b-main-inner {
  @media screen and (max-width: 991px) {
    padding: 0 10px;
  }
}

@media screen and (max-width: 480px) {
  main[class*='page-matvaror'],
  main[class*='page-kundservice'] {
    &.b-main {
      padding: 0;
      .b-main-inner {
        padding: 0;
        .c-pagetop,
        .c-category-filters {
          margin: 2px 0 1px 0;
        }
      }
    }
    .c-category-filters {
      &__filters {
        display: flex;
        justify-content: space-evenly;
        .c-cmdbtn {

        }
      }
    }
  }
}
