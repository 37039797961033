@import "../../styles/_variables.scss";

.sticky-header .b-searchsuggest {

  &.noticeHidden {
    top: 58px;
  }
}
_:-ms-fullscreen,
:root .b-searchsuggest {
  height: 100%;
  
}

.c-search-result {
  &__view-all {
    padding: 0 5px 5px;
    text-align: center;
    margin-top: map-get($spacers, 6);

 
  }
}
.c-search-view-all{
  &__desktop{
    display:none;
    @media(min-width: $screen-md-min){
      display:block
    }
  }
  &__mobile{
    display:block;
    @media(min-width: $screen-md-min){
      display:none;
    }
  }
}
.b-searchsuggest {
  display: flex;
  flex-direction: column;
  z-index: 4;

  &.noticeHidden {
    top: 104px;
  }

  .scrollable {
    padding: 0px;
  }

  &__completions {
    min-height: map-get($spacers, 4);
  }

  &__test {
    align-items: baseline;

    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 20px;

    @media screen and (min-width: $screen-md-min) {
      display: flex;
    }
  }

  &__result {
    overflow-y: auto;
    padding: 12px;
    overflow-x: hidden;
    max-height: calc(100vh - 65px);
    // @supports (max-height: 100dvh) {
    // max-height: calc(100dvh - 65px);
    // }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #e7e7e7;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }
  &__empty {
    padding: 0px 10px;
  }

  &__recent-popular {
    margin: map-get($spacers, 4) map-get($spacers, 2);
    @media screen and (min-width: $screen-md-min) {
      margin: map-get($spacers, 4) 0 map-get($spacers, 2) 0;
    }
    li {
      a {
        display: flex;
        align-content: center;
        height: 35px;
        line-height: 22px;
        margin-top: map-get($spacers, 2);
        > span {
          margin-right: map-get($spacers, 2);
        }
        img {
          transform: scaleX(-1);
          opacity: 0.6;
        }
      }
    }
  }
  .meta {
    margin-right: 10px;
  }

  .c-producttable-product__qty .buy-button .addtocart {
    width: 140px;
    height: $button-default-height;
    .c-cmdbtn {
      height: $button-default-height;
      &[disabled] {
        padding-top: 1px;
        border: none;
      }
    }
    .changeqty {
      height: $button-default-height;
      margin: 4px 0px;
      &.changeqty-animation-enter,
      &.changeqty-animation-exit {
        margin-top: -36px;
      }
    }
    .active {
      margin-top: 2px;
    }
  }

  .scrollable__container .closepanel {
    background-color: rgb(247, 247, 247);
    text-align: right;
    padding: 0px 20px 0px 0px;
    margin-bottom: 20px;

    .c-removebtn {
      background-color: #fff;
    }
    .c-removebtn:hover {
      background-color: #ffe876;
    }
  }

  .scrollable__container > div:last-child {
    margin-bottom: 0px;
  }

  .c-searchhit {
    padding: 10px;
  }

  .b-searchcontainer.fullheight & {
    top: 139px;

    &.noticeHidden {
      top: 105px;
    }
  }
  .b-searchcontainer.stickyheight & {
    top: 87px;

    &.noticeHidden {
      top: 54px;
    }
  }
  .search {
    text-align: left;

    &:hover .name {
      text-decoration: underline;
    }
  }

  .c-producttable-product.search .c-pricetag {
    margin-right: 17px;
  }
  .c-producttable-product.search .c-pricetag.isExtraPrice {
    margin-right: 15px;
    .c-pricetag__effect-type {
      font-size: 1rem;
    }
  }

  .c-producttable-product {
    padding: 5px 10px;
    margin-bottom: 2px;
    margin-right: 0;
  }

  h2 {
    display: flex;

    .title {
      flex: 1;
    }
    .showAll {
      color: #005995;
    }
  }

  .c-producttable-product__meta {
    min-height: 40px;
    img {
      height: 40px;
      margin-right: 17px;
    }
    .name {
      margin-top: 0px;
    }
  }

  .is-product {
    .image {
      width: 40px;
      height: 40px;
      min-width: 40px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 20px;
    }
  }
  .is-foodbag {
    .image {
      width: 64px;
      height: 64px;
      min-width: 64px;
      background-size: cover;
      background-position: center;
      margin: 8px 28px auto 8px;
    }
  }
  .extended {
    align-items: center;
    .price {
      padding: 5px 30px 5px 0px;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  .c-searchhit .descr {
    margin: 2px 0px 8px 0px;
  }
  .c-searchhit .descr {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .is-recipe {
    .image {
      width: 80px;
      height: 80px;
      min-width: 80px;
      margin-bottom: auto;
      background-size: cover;
      background-position: center;
      margin-right: 20px;
    }
  }

  @media screen and (min-width: 992px) {
    left: 226px;
    right: 84px; //alternatively 138px
    // TODO see above
    // height: calc(100% - 124px);
    .scrollable {
      padding: 0px 20px;
    }
    .cards .card {
      width: 25%;
    }
    h2 {
      font-size: 12px;
      text-transform: uppercase;
    }
    .hit {
      background: white;
      font-size: 16px;
      padding: 7px 18px;
      margin-bottom: 2px;
    }
  }

  @media screen and (min-width: 1360px) {
    left: 226px;
    right: 322px;
  }

  @media screen and (min-width: 1900px) {
    left: calc((100% - 1860px) / 2 + 226px);
    right: calc((100% - 1860px) / 2 + 322px);
  }

  @media screen and (max-width: 991px) {
    left: 0;
    top: 60px;
    width: 100%;

    .scrollable__container .mobile.fixed {
      display: block;
    }
    .closepanel {
      display: none;
    }

    h2 {
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 10px;
      margin-right: 10px;
    }
    .hit {
      background: white;
      font-size: 14px;
      padding: 7px 10px;
      margin-bottom: 2px;
    }
    .search {
      margin-left: 9px;
      margin-right: 9px;
    }
  }
  @media screen and (min-width: 992px) {
    h2 {
      font-size: 12px;
      text-transform: uppercase;
      margin: 0 0 6px;
    }
  }

  @media screen and (max-width: 767px) {
    .b-searchsuggest {
      max-height: calc(100% - 58px);
    }
    .c-producttable-product.search {
      height: auto;

      .c-producttable-product__meta {
        min-height: 50px;
      }

      .c-producttable-product__meta::after {
        display: none;
      }
      .in-dropdown {
        visibility: visible;
      }
      .in-dropdown.c-producttable-product__qty {
        margin-top: 0px;
        .addtocart .c-cmdbtn,
        .changeqty .c-cmdbtn {
          height: $button-default-height;
        }
      }

      .c-pricetag {
        padding: 10px;
        margin-right: 10px;
        margin-left: 50px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .c-producttable-product.search {
      .c-pricetag {
        margin-left: 50px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .c-producttable-product.search {
      .c-pricetag.isExtraPrice {
        margin-top: 4px;
      }
      .c-pricetag:not(.isExtraPrice) {
        padding-top: 0px;
      }
      .c-pricetag,
      .c-pricetag.isExtraPrice {
        margin-left: 70px;
        margin-right: 100%;

        margin-bottom: 8px;
      }
      .in-dropdown.c-producttable-product__qty {
        margin-left: 70px;
      }
    }
    .is-recipe,
    .is-foodbag,
    .is-product {
      .image {
        width: 60px;
        height: 60px;
        min-width: 40px;
        position: absolute;
        top: 0px;
        left: 0px;
        margin: 0px;
      }
      .meta {
        margin-left: 80px;
      }

      .extended {
        flex-wrap: wrap;
        position: relative;
        .price {
          flex: none;
          margin-left: 60px;
        }
      }
      .meta {
        width: calc(100% - 70px);
      }
    }
  }
}
