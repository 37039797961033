.c-shrinkreveal {

  h3 {
    margin: 0 0 0.2rem;
  }
  p {
    margin-bottom: 0;
  }
  &__content {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;

    &.collapsed {
      max-height: 270px;

      &:after {
        display: block;
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(247, 247, 247, 1));
      }
    }
  }

  &__toggle {
    color: #0071b9;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
  }
}
