.text-tiny {
  font-size: 10px;
}

.button {
  width: 122px;

  .buy-button {
    width: 100%;

    > div {
      width: 100%;
    }
  }
}

.not-available {
  align-self: center;
  font-size: 12px;
  line-height: 18px;
}
