@import '../../../styles/breakpoints';

.no-store-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: $screen-phone) {
    padding: 16px;
  }

  @media screen and (min-width: $screen-phone) {
    gap: 24px;
  }

  .weekly-offers {
    display: flex;

    img {
      max-width: 100%;
    }

    @media screen and (max-width: $screen-phone) {
      flex-direction: column;
    }

    @media screen and (min-width: $screen-phone) {
      margin-bottom: 20px;

      > * {
        flex: 1;
      }
    }

    > :nth-child(1) > :first-child {
      @media screen and (min-width: $screen-phone) {
        display: flex;
        justify-content: center;
        height: 100%;
      }

      img {
        object-fit: contain;
      }
    }

    > :nth-child(2) > :first-child {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: center;

      @media screen and (min-width: $screen-phone) {
        justify-content: center;
        height: 100%;
      }

      .title {
        font-size: 17px;
        font-weight: 600;
        line-height: 25.5px;

        @media screen and (min-width: $screen-phone) {
          font-size: 30px;
          font-weight: 700;
          line-height: 45px;
        }
      }

      .subtitle {
        font-size: 15px;
        line-height: 22.5px;

        @media screen and (min-width: $screen-phone) {
          font-size: 18px;
          line-height: 27px;
        }
      }

      button {
        @media screen and (min-width: $screen-phone) {
          align-self: center;
        }
      }

      .button-text {
        font-size: 15px;
        font-weight: 500;
        line-height: 22.5px;

        @media screen and (min-width: $screen-phone) {
          padding: 4px;
        }
      }
    }
  }
}
