@import './colors';
@import './breakpoints';

// Allows for customizing button radius independently from global border radius
$button-border-radius: 2px;
$button-default-height: 31px;

// Custom CG Headers
$header-big-height: 110px;
$header-small-height: 66px;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default; // 16px
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.3125 // 5px
    ),
    2: (
      $spacer * 0.625 // 10px
    ),
    3: (
      $spacer * 0.9375 // 15px
    ),
    4: (
      $spacer * 1.25 // 20px
    ),
    5: (
      $spacer * 1.875 // 30px
    ),
    6: (
      $spacer * 2.5 // 40px
    )
  ),
  $spacers
);

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg: $color-black-lightest !default;
//** Global text color on `<body>`.
$text-color: $color-black !default;

//** Global textual link color.
$link-color: $color-black !default;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: 'Poppins', sans-serif !default;
$font-family-base: $font-family-sans-serif !default;
$font-weight-base: 400;

$font-size-base: 1rem !default;
$font-size-large: ($font-size-base * 1.125) !default; // ~18px
$font-size-small: ($font-size-base * 0.875) !default; // ~14px

$h1-font-size: $font-size-base * 2.065 !default; // ~33px
$h2-font-size: $font-size-base * 1.5 !default; // 24px
$h3-font-size: $font-size-base * 1.125 !default; // ~18px //C18.767
$h4-font-size: $font-size-base * 1 !default; // 16px
$h5-font-size: $font-size-base * 0.875 !default; // ~14px
$h6-font-size: $font-size-base * 0.75 !default; //~12px

//## Font, line-height, and color for body text, headings, and more.
$font-size-lg: ($font-size-base * 1.125) !default; // ~18px
$font-size-sm: ($font-size-base * 0.875) !default; // ~14px
$font-size-xsm: ($font-size-base * 0.75) !default; // ~12px
$font-size-xxs: ($font-size-base * 0.625) !default; // ~10px

$headings-margin-bottom: ($spacer / 2) !default;
$headings-font-family: inherit !default;
$headings-font-weight: 600 !default;
$headings-line-height: 1.3 !default;
$headings-color: inherit !default;

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
  color: $headings-color;
}

h1,
.h1 {
  font-size: $h1-font-size;
}
h2,
.h2 {
  font-size: $h2-font-size;
}
h3,
.h3 {
  font-size: $h3-font-size;
}
h4,
.h4 {
  font-size: $h4-font-size;
}
h5,
.h5 {
  font-size: $h5-font-size;
}
h6,
.h6 {
  font-size: $h6-font-size;
}

@media screen and (max-width: $screen-xs) {
  h1,
  .h1 {
    font-size: $h2-font-size;
  }
  h2,
  .h2 {
    font-size: $h3-font-size;
  }
  h3,
  .h3 {
    font-size: $h4-font-size;
  }
  h4,
  .h4 {
    font-size: $h5-font-size;
  }
  h5,
  .h5 {
    font-size: $font-size-xsm;
  }
  h6,
  .h6 {
    font-size: $h6-font-size;
  }
}
