/* Component: product list */
.c-prodlist {
  margin-bottom: 20px;
}
.c-prodlist .card.double {
  grid-column: span 2;
}
.c-prodlist .card.extradouble {
  display: none;
}
@media screen and (max-width: 449px) {
  .c-prodlist .card .c-fitrecipecard .arrow {
    display: none;
  }
}
@media screen and (min-width: 450px) {
  .c-prodlist .card {
    width: calc(100% / 2);
  }
  // .c-prodlist .card.double {
  //   width: calc(100% / 2 * 2);
  // }
  .c-prodlist .card:nth-child(2n + 1) .c-fitrecipecard .arrow {
    display: none;
  }
}
@media screen and (min-width: 544px) {
  .c-prodlist .card {
    width: calc(100% / 3);
  }
  // .c-prodlist .card.double {
  //   width: calc(100% / 3 * 2);
  // }
  .c-prodlist .card:nth-child(3n + 1) .c-fitrecipecard .arrow {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .c-prodlist .card {
    width: calc(100% / 4);
  }
  // .c-prodlist .card.double {
  //   width: calc(100% / 4 * 2);
  // }
  .c-prodlist .card:nth-child(4n + 1) .c-fitrecipecard .arrow {
    display: none;
  }
}
@media screen and (min-width: 1530px) {
  .c-prodlist .card {
    width: calc(100% / 5);
  }
  // .c-prodlist .card.double {
  //   width: calc(100% / 5 * 2);
  // }
  .c-prodlist .card:nth-child(5n + 1) .c-fitrecipecard .arrow {
    display: none;
  }
}
@media screen and (min-width: 1800px) {
  .c-prodlist .card {
    width: calc(100% / 6 - 0.1px);
  } /* WORKAROUND: or Edge won't fit 6 cards probably because of rounding-issues */
  // .c-prodlist .card.double {
  //   width: calc(100% / 6 * 2);
  // }
  .c-prodlist .card:nth-child(6n + 1) .c-fitrecipecard .arrow {
    display: none;
  }
}
