@import '../../../styles/breakpoints';

.leaflet-wrapper {
  margin-bottom: 20px;

  .leaflet-container {
    @media screen and (min-width: $screen-tablet) {
      position: relative;
    }

    .store-selected {
      @media screen and (min-width: $screen-tablet) {
        padding: 8px 16px;
      }
    }

    .no-store-selected {
      @media screen and (min-width: $screen-tablet) {
        padding: 24px 40px;
      }
    }

    .leaflet {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .upper {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .content {
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title {
            font-size: 17px;
            font-weight: 600;
            line-height: 25.5px;

            @media screen and (min-width: $screen-tablet) {
              font-size: 30px;
              font-weight: 700;
              line-height: 45px;
            }
          }

          .subtitle {
            font-size: 15px;
            line-height: 22.5px;
          }
        }

        .image-container {
          @media screen and (min-width: $screen-tablet) {
            position: absolute;
            right: 24px;
            bottom: 0;
          }

          img {
            width: 60px;
            vertical-align: bottom;

            @media screen and (min-width: $screen-tablet) {
              width: 146px;
            }
          }
        }
      }

      .button-container,
      .button-container a {
        @media screen and (max-width: $screen-tablet) {
          display: grid;
        }
      }
    }
  }
}
