/* Component: overlay */
.c-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #333;
  opacity: 0.5;
}

.c-overlay.aboveheader {
  z-index: 3;
}
.c-overlay.aboveflyout {
  z-index: 5;
}

.c-overlay-enter,
.c-overlay-appear {
  opacity: 0;

  &-active {
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
  }
}

.c-overlay-exit {
  opacity: 0.5;

  &.c-overlay-exit-active {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
}
