.c-simple-teaser {
  display: block;
  background-color: #333;
  height: 0;
  position: relative;
  padding-bottom: percentage(280 / 400);

  @media screen and (min-width: 544px) {
    padding-bottom: percentage(280 / 445);
  }

  &--has-link:hover &__text {
    background-color: #ffe522;
    color: #333;
  }

  .c-double-teaser-container & {
    height: calc((150px - 20px) / 2);
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    background-color: rgba(51, 51, 51, .5);
    color: white;
  }

  &__title {
    font-size: 15px;
    font-weight: 500;
    hyphens: auto;

    @media screen and (min-width: 544px) {
      font-size: 18px;
    }

    @media screen and (min-width: 992px) {
      font-size: 23px;
    }
  }

  &__subtitle {
    font-size: 11px;

    @media screen and (min-width: 544px) {
      font-size: 16px;
    }
  }
}
