.c-toggleable-text{
  background: #fff;
  margin-bottom: 20px;
  box-shadow: 0 1px 0 0 rgba(0,0,0,.1);
  border: 1px solid #e7e7e7;
  font-size: 14px;

  &__heading {
    font-weight: 500;
    padding: 19px 40px 19px 15px;
    cursor: pointer;
    position: relative;
  }
  &__indicator {
    position: absolute;
    right: 15px;
    top: 20px;
  }
  &__description {
    padding: 20px 15px;
    border-top: solid 1px rgba(51,51,51,.05);
    line-height: 1.3;
  }
}
