@import '../../styles/_variables.scss';

$button-size: 32px;
$button-size-small: 24px;

.c-btn-round{
  display: inline-block;
  width: $button-size;
  height: $button-size;
  border: 0;
  border-radius: 20px;
  background: #e7e7e7;
  background-repeat: no-repeat;
  box-shadow: 0 1px 0 0 rgba(0,0,0,.1);
  transition: background-color ease-in-out .2s;
  cursor: pointer;
  position: relative;
  vertical-align: top;

  background-color: $color-button-grey-static;
  box-shadow: 0 1px 0 0 $color-button-grey-shadow;

  &.disabled {
    cursor: not-allowed;
    background-color: $color-black-lighter;
    opacity: 0.5;

    &:hover {
      background-color: $color-black-lighter;
      box-shadow: 0 1px 0 0 $color-button-grey-shadow;
    }
  }

  &.small {
    width: $button-size-small;
    height: $button-size-small;
    background-size: 14px 15px;
    background-position: 5px 5px;
    &:hover {
      height: $button-size-small;
      background-color: $color-button-primary-hover;
      box-shadow: 0 2px 0 0 $color-button-primary-shadow;
    }
    &.disabled {
      &:hover {
        background-color: $color-black-lighter;
        box-shadow: 0 1px 0 0 $color-button-grey-shadow;
      }
    }
  }

  &.primary {
    background-color: #ffe522;
    box-shadow: 0 1px 0 0 #ebc92a;
    &.disabled {
      cursor: default;
      background-color: $color-button-primary-disabled;
    }
  }

  &.transparent {
    background-color: transparent;
    box-shadow: none;
    &:hover {
      background-color: transparent;
      box-shadow: none;
    }
  }

  &.light:not(:hover) {
    background-color: $color-white;
  }

  &:hover {
    background-color: $color-button-primary-hover;
    box-shadow: 0 2px 0 0 $color-button-primary-shadow;
  }

  &:active {
    margin-top: 1px;
    height: $button-size - 1;
    background-color: $color-button-primary-active;
    box-shadow: 0 -1px 0 0 $color-button-primary-shadow;
  }

  &__spinner {
    margin: 3px 0 0 3px;
    width: 26px;
    height: 26px;
  }
}
