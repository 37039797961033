.c-singelstore {
  &__info {
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      display: block;
    }

  }
  &__infocolumn {
    flex: 1;
    padding: 0 40px;
    margin: 20px 0;

    @media screen and (max-width: 767px) {
      margin: 40px 0;
      padding: 0;
    }

    &--gray {
      padding-top: 40px;
      padding-bottom: 40px;

      @media screen and (min-width: 767px) {
        background: #f7f7f7;
      }
      @media screen and (max-width: 767px) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    p {
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 20px;
    }
  }
}
