/* Component: tabbed content */

.c-tabbedcontent {

  &--default {
    .tabs {
      @media screen and (min-width: 768px) {
        // margin-bottom: 13px;
        border-bottom: 4px solid white;
      }
    }
    .tab {
      display: inline-block;
      padding: 16px 20px 12px;
      font-size: 14px;
      background-color: #e7e7e7;
      font-weight: 600;
      cursor: pointer;

      @media screen and (min-width: 768px) {
        margin-right: 5px;
      }

      &.active {
        background-color: #fff;
        text-decoration: underline;
      }

      .lessimportant {
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
    .content {
      background-color: white;
    }
    .content {
      padding: 20px;
      &:not(.active) {
        display: none;
      }
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
  }

  &--plain {
    .tabs {
      overflow: hidden;
      margin-bottom: 50px;
    }
    .tab {
      font-size: 19px;
      font-weight: 600;
      text-transform: uppercase;
      @media screen and (min-width: 768px) {
        display: inline-block;
        margin-right: 55px;
        cursor: pointer;
      }

      &.active {
        @media screen and (min-width: 768px) {
          border-bottom: 4px solid #ffe522;
        }
      }
    }
    .content {
      &:not(.active) {
        display: none;
      }
    }
  }
}
