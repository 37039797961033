.c-progressive-picture {
  $root: &;

  position: relative;
  overflow: hidden;
  z-index: 0;

  &__picture {
    height: inherit;
  }

  &__img,
  &__fallback,
  &__thumb,
  &__spinner,
  &__error {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__thumb,
  &__fallback {
    opacity: 0;
    transition: opacity .25s ease-in-out;
  }

  &__img {
    &--mounted {
      opacity: 0;
      transition: opacity .25s ease-in-out;
    }
  }

  &__fallback,
  &__img {
    &--loaded {
      z-index: 2;
    }
  }

  &__thumb,
  &__fallback,
  &__img {
    &--loaded {
      opacity: 1;
    }
  }

  &__thumb,
  &__img {
    &--hidden {
      opacity: 0;
    }
  }

  &__img {
    #{$root}--cover & {
      object-fit: cover;
    }

    #{$root}--contain & {
	  object-fit: contain;
	  width:auto;
	  height:auto;
	  max-width:100%;
	  max-height:100%;
    }

    #{$root}--left & {
      object-position: left;
    }

    #{$root}--right & {
      object-position: right;
    }

    #{$root}--top & {
      object-position: top;
    }

    #{$root}--bottom & {
      object-position: bottom;
    }

    #{$root}--center & {
	  object-position: center;
	  margin:auto;
    }
  }

  &__thumb {
    // Makes the tiny w=10px image less pixelated :)
    filter: blur(20px);
    // Scaling hides the transparent soft edges caused by the blur
    transform: scale(1.1);
  }

  &__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: .2;
  }

  &__ratio {
    height: 0;

    &--lg {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &--sm {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }
}
