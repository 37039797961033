/* Component: login */
.c-login {
  h3 { margin: 0; }
  .box {
    margin-bottom: 20px;
    @media screen and (min-width: 544px) {
      background-color: white; border-radius: 2px; padding: 24px;
    }
  }

  .formfield {
    margin-bottom: 24px;
  }
}

/* Component: forgotpassword */
.c-login-forgotpassword {
  font-size: 14px; 
  text-decoration: underline; 
  cursor: pointer; 
  display: inline-block; 
  margin-top: 10px; 
}


/* Component: create account */
.c-login-createaccount {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: 544px) {
    background-color: white; border-radius: 2px; padding: 24px;
  }

  img {
    max-width: 64px;
  }

  &__boxwrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__explain { 
    margin-bottom: 0px; 
    @media screen and (max-width: 543px) {
      font-size: 14px;
    }
    @media screen and (min-width: 544px) {
      font-size: 16px;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
}
