@import '../breakpoints';

$colCount: 60 !default;
$gridName: grid-container !default;
$colName: l-column !default;
$attributeName: class !default;
$gutter: 1rem !default; // Total left + right
$gutter-vertical: 1rem !default;
$mq-width: 'max-width' !default;
$mq-list: (
  xlg: $screen-xlg + 4000,
  lg: $screen-lg + 220,
  md: $screen-md + 220,
  sm: $screen-sm + 20,
  xs: $screen-xs + 120
) !default;
