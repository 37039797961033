@import '../../../../styles/variables';

.c-newCartPreview {
  max-width: 550px;
  width: 100%;
  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
  .c-newCartPreview__title{
    margin:0;
    font-size:18px;
  }
  .c-newCartPreview__info {
    display:flex;
    flex-direction: column;
    gap: 16px;
    * > {
      margin: 0;
    }
  }

  &__productwrap {
    position: relative;
    margin: 20px 0;
    display:flex;
    gap: 8px;
    flex: 1;
  }

  &__products {
   border-radius: 4px;
   border: 1px solid #ebebeb;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    flex:1;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 6px; /* Mostly for vertical scrollbars */
      height: 10px; /* Mostly for horizontal scrollbars */
    }
  &::-webkit-scrollbar-thumb { /* Foreground */
      background: #888;
    }

    @media screen and (max-width: 480px) {
      max-height: 300px;
    }
  }

  &__product {
    display: flex;
    background: #fff;
    border-bottom: 1px solid #fbfbfb;
    padding: 15px;
    flex: 1;
    align-items: center;
    font-size: 14px;

    @media screen and (max-width: 480px) {
      padding: 5px;
      font-size: 12px;
    }

    img {
      max-width: 100%;
      max-height: 40px;
      max-width: 40px;

      @media screen and (max-width: 480px) {
        max-height: 30px;
        max-width: 30px;
      }

    }
  }

  &__productimage {
    display: flex;
    max-width: 100%;
    width: 50px;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &__disclaimer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(101, 101, 101, 0.1);
  }
  &__disclaimerinner {
    background: #ededed;
    display: inline-block;
    padding: 15px;
    border-top: 3px solid #ececec;
  }
  &__error {
    background: #fffbe7;
    padding: 10px;
    font-weight: 400;
    margin-bottom: 10px;
    color:red;
  }

  &__description {
    flex-direction: column;

    &--replaced{
      color: $color-grey
    }
  }

  &__priceSection{
    margin-left: auto;
    display:flex;
    text-align: right;
  }

  &__priceDiff {
    padding-right: 8px;
    text-decoration: line-through;
  }
  &__descriptive {
    font-size: 11px;
    color: $color-grey;
  }

  &__price {
    font-weight: 500;
  }

  &__loading {
    height: 200px;
    display:flex;
    justify-content: center;
    align-items:center;
  }

  &__buttons {
    display:flex;
    gap: 10px;
    justify-content: flex-end;
    > * {
      flex: 50%;
      max-width:50%;
    }
   
  }

}


