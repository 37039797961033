.c-faqmodule {
  &__filters {
    margin-bottom: 25px;
  }

  &__filter {
    margin-bottom: 5px;
    display: inline-block;
    @media screen and (min-width: 768px){
      width: 170px;
      margin-right: 5px;

    }
    @media screen and (max-width: 768px){
      width: 100%;
    }
  }
}
