.c-account-header {
  margin-bottom: 30px;
}

.c-account-header h1 {
  margin: 0;
}

.c-account-header-meta {
  float: right;
  line-height: 38px;
}

.c-account--empty {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px rgba(0, 0, 0, .1);
}

@media screen and (max-width: 991px) {
  .c-account-header {
    margin-bottom: 10px;
  }
}

/* Not the original design, might want to remove styling below this comment */
.c-account-header h1 {
  font-size: 33px;
  font-weight: 600;
}
.c-account-header .c-breadcrumbs {
  box-shadow: 0 1px rgba(0, 0, 0, .1);
}
.c-account-header-inner {
	margin: 40px 0px 50px 0px;

	.intro {
		margin-top:5px;
		max-width:700px;
	}
}
.c-account-header .c-breadcrumbs {
  position: relative;
  border-radius: 2px 2px 0 0;
  margin-bottom: 0;
}
