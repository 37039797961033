.c-tooltip {
  position: relative;
  
  &:before {
    content: "";
    position: absolute;
    top:-6px;
    left:50%;
    transform: translateX(-50%);
    border-width: 4px 6px 0 6px;
    border-style: solid;
    border-color: rgba(0,0,0,0.7) transparent transparent transparent;
    z-index: 99;
    opacity:0;
  }

  &[tooltip-position='left']::before{
    left:0%;
    top:50%;
    margin-left:-12px;
    transform:translatey(-50%) rotate(-90deg)
  }
  &[tooltip-position='top']::before{
    left:50%;
  }
  &[tooltip-position='buttom']::before{
    top:100%;
    margin-top:8px;
    transform: translateX(-50%) translatey(-100%) rotate(-180deg)
  }
  &[tooltip-position='right']::before{
    left:100%;
    top:50%;
    margin-left:1px;
    transform:translatey(-50%) rotate(90deg)
  }

  &[data-tooltip]::after {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    color: #fff;
    content: attr(data-tooltip);
    font-size: 12px;
    min-width: 80px;
    opacity: 0;
    padding: 4px 4px;
    pointer-events: none;
    position: absolute;
    text-align: center;
    z-index: 99;
  }

  &[tooltip-position='left']::after {
    right: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);
  }
  &[tooltip-position='top']::after {
    right: 50%;
    top: -6px;
    transform: translateX(50%) translateY(-100%);
  }
  &[tooltip-position='bottom']::after {
    right: 50%;
    margin-top: 8px;
    top: 100%;
    transform: translateX(50%);
  }
  &[tooltip-position='right']::after {
    left: calc(100% + 8px);
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-tooltip]:hover::after,
  &[data-tooltip]:hover::before {
    opacity:1;
  }
}
