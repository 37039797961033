.c-error-info {
  $root: &;

  color: #333;
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 20px;

  &--side-margins {
    margin-left: 20px;
    margin-right: 20px;
  }

  &__inner {
    display: flex;
    width: 100%;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    flex-direction: column;
    background: #fffae2;
    border-top: 3px solid #ffe522;
    box-shadow: 0 5px 12px rgba(0, 0, 0, 0.12);

    #{$root}--full-width & {
      max-width: 100%;
    }

    #{$root}--left-align & {
      margin-left: 0;
    }
  }

  &__title {
    margin: 0;
  }

  &__text + &__offline,
  &__title + &__text {
    margin-top: 1em;
  }

  &__retry {
    margin-top: 1em;
    margin-left: auto;
  }
}
