
.topnav-navlink {
  height: 100%;
  display: inline-flex;
  cursor: pointer;
  -webkit-box-align: center;
  align-items: center;
  white-space: nowrap;
  font-weight: 600;
  &.active{
    box-shadow: inset 0 -4px 0 #ffe522;
  }
}

.header-search__cancel {
  display:flex;
  align-items: center;
  justify-content: center;
  background: #F7F7F7  ;
  border-radius: 100%;
  height: 24px;
  width: 24px;

  &:hover{
    background: #E8E8E8;
  }
}
.header-search__cancel-text {
  font-size: 13px;
  @media(min-width: 991px){
    font-size: 15px;
  }
}
.header-pointer__container{
  cursor: pointer;
}

.header-search__icon {
  display:grid;
  place-content: center;
}

.header-content__right {
  display:flex;
  align-items: center;
  margin-left: 4px;
  > button {
    margin-right: 8px;
  } 
  > * > button{
    margin-right: 8px;

  }
 
}
