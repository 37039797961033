@import '../Skeletons.scss';

.c-skeleton__recipecard {
  $recipe-card-height: 416px;

  $recipe-image-height: 230px;
  $recipe-image-skeleton: linear-gradient($color-grey $recipe-image-height, transparent 0);

  $recipe-title-height: 1rem;
  $recipe-title-width: 30%;
  $recipe-title-position: $card-padding calc(#{$recipe-image-height} + 15px);
  $recipe-title-skeleton: linear-gradient($color-grey $recipe-title-height, transparent 0);

  $recipe-desc-line-height: 19px;
  $recipe-desc-line-skeleton: linear-gradient($color-grey $recipe-desc-line-height, transparent 0);
  $recipe-desc-line-width: 85%;
  $recipe-desc-line-1-width: $recipe-desc-line-width + 5%;
  $recipe-desc-line-2-width: $recipe-desc-line-width - 5%;
  $recipe-desc-line-1-position: $card-padding calc(#{$recipe-image-height} + 15px + 15px + #{$recipe-title-height} + #{$card-padding});
  $recipe-desc-line-2-position: $card-padding calc(#{$recipe-image-height} + 15px + 15px + #{$recipe-title-height} + #{$card-padding} + #{$recipe-desc-line-height} + 5px);

  $recipe-button-width: 100px;
  $recipe-button-position: calc(100% - #{$card-padding}) calc(100% - #{$card-padding});
  $recipe-button-skeleton: linear-gradient($color-grey $button-height, transparent 0);

  $recipe-blur-size: $blur-width $recipe-card-height;

  min-height: 403px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  &::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 1px 0 0 #d7d7d7;
    // box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg,
        rgba(white, 0) 0,
        rgba(white, .33) 100%
      ),                    //animation blur
      $recipe-title-skeleton,      //title
      $recipe-desc-line-skeleton,  //desc1
      $recipe-desc-line-skeleton,  //desc2
      $avatar-skeleton,     //avatar
      $recipe-button-skeleton,     //button
      $recipe-image-skeleton       //card
    ;

    background-size:
      $recipe-blur-size,
      $recipe-title-width $recipe-title-height,
      $recipe-desc-line-1-width $recipe-desc-line-height,
      $recipe-desc-line-2-width $recipe-desc-line-height,
      $avatar-size $avatar-size,
      $recipe-button-width $button-height,
      100% 100%
    ;

    background-position:
      calc(-100% - #{$blur-width} - #{$card-padding}) 0, //animation
      $recipe-title-position,        //title
      $recipe-desc-line-1-position,  //desc1
      $recipe-desc-line-2-position,  //desc2
      $avatar-position,       //avatar
      $recipe-button-position,       //button
      0 0                     //card
    ;

    background-repeat: no-repeat;
    animation: loadingrecipecard 1.2s infinite;

    @keyframes loadingrecipecard {
      to {
        background-position:
          calc(100% + #{$blur-width} + #{$card-padding}) 0,
          $recipe-title-position,
          $recipe-desc-line-1-position,
          $recipe-desc-line-2-position,
          $avatar-position,
          $recipe-button-position,
          0 0
        ;
      }
    }
  }
}
