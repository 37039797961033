.m-forgotpassword {
  margin-top: 70px;

  &__box {
    margin-bottom: 20px;
    @media screen and (min-width: 544px) {
      background-color: #fff;
      border-radius: 2px;
      padding: 20px;
    }
  }
}
