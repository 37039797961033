@import '../../../styles/variables.scss';

.c-togglebtn {
  $root: &;
  color: #555;
  font-size: 12px;
  font-weight: 500;
  // height: 31px;
  padding: 8px 10px 6px;
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 0 0 rgba(0,0,0,.1);
  border: solid 1px rgba(0,0,0,.1);
  cursor: pointer;

  &__content{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__icon{
    &.active {
      filter: invert(100%);
    }
  }

 &.active {
    color: #fff;
    background-color: #a7a7a7;
    border-color: transparent;
    border-top-color: rgba(51,51,51,.2);
  }

  &.fullwidth {
    width: 100%;
  }

  &.highlighted {

    &:disabled {
      background-color: transparent;
      color: #a7a7a7;
    }
    &.active {
      color: #fff;
      background-color: $color-blue-dark;
    }
  }
}
