// calculate width of one col in %
$colUnit: (100% / $colCount);

// calculate and store nth portions for grid by columns
$colPortions: (
  0: 0
);
@for $i from 1 through $colCount {
  $colPortions: map-merge(
    $colPortions,
    (
      $i: (
        $colUnit * $i
      )
    )
  );
}

// calculate and store nth fractions for grid by number
$colFractions: (
  0: 0 // Avoid division by zero if $i would start at 0
);
@for $i from 1 through $colCount {
  $fraction: 1 / $i;
  $colFractions: map-merge(
    $colFractions,
    (
      $fraction: $fraction * 100%
    )
  );
}
