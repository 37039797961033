@import '../../styles/variables';

.p-singlestore {
  margin-top: -20px;

  a {
    color: $color-blue-dark;
  }

  &__wrapper {
    background: #fff;
    margin: -100px 15px 40px;
    min-height: 300px;
    padding: 20px;
    position: relative;

    @media screen and (max-width: 767px) {
      margin: -40px 0px;
    }
  }
  &__container {
    max-width: 980px;
    margin: 0 auto;
  }

  &__storeimage {
    margin: 0 -10px;
    height: 500px;

    @media screen and (max-width: 767px) {
      height: 250px;
    }
  }

  &__header {
    text-align: center;
    padding: 40px;
    border-bottom: 1px solid #e4e4e4;

    @media screen and (max-width: 767px) {
      font-size: 12px;
      padding: 0;
      padding-bottom: 40px;
    }

    h1 {
      margin-bottom: 25px;
    }

  }
  &__headerlink {
    position: absolute;
    top: 30px;
    right: 40px;

    @media screen and (max-width: 767px) {
      position: static;
      text-align: center;
    }

    a {
      font-size: 13px;
    }
  }

  &__info {
    display: flex;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      display: block;
    }

  }
  &__infocolumn {
    flex: 1;
    padding: 0 40px;
    margin: 20px 0;

    @media screen and (max-width: 767px) {
      margin: 40px 0;
      padding: 0;
    }

    &--gray {
      padding-top: 40px;
      padding-bottom: 40px;

      @media screen and (min-width: 767px) {
        background: #f7f7f7;
      }
      @media screen and (max-width: 767px) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    p {
      margin-bottom: 20px;
    }
    h2 {
      margin-bottom: 20px;
    }
  }

  &__storeservice {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      max-width: 100%;
      width: 35px;
      margin-right: 15px;
    }
  }
}
