.c-stdradiobtn {
  display: block;

  input {
    display: none;
  }

  i {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url("../../../assets/radiobutton-off.svg") left top no-repeat;
    margin-right: 2px;
  }

  input:checked + i {
    background-image: url("../../../assets/radiobutton-on.svg");
  }

  &.large {
    i {
      width: 24px;
      height: 24px;
    }

    .label {
      font-size: 14px;
      float: right;
      width: calc(100% - 35px);
      line-height: 26px;
    }
  }
}
