@import '../../styles/variables';
.mobile-footer{
  padding-bottom: 80px !important;
}
.b-footer {
  a:hover {
    text-decoration: underline;
  }
  @media screen and (max-width: $screen-xs-max) {
    border-top: 2px solid $color-black-lightest;
  }

  .contact {
    background: white;
    font-size: 19px;
    font-weight: 500;
    text-align: center;

    .poc img {
      display: block;
      height: auto;
      margin: 0 auto;
      width: auto;
    }
  }

  .links {
    background: white;
    padding-top: 20px;
  }

  .c-plusminus {
    height: 40px;
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40px;

    &:before,
    &:after {
      background: #054066;
      content: '';
      display: block;
      height: 1px;
      left: 16px;
      position: absolute;
      top: 19px;
      transition: transform ease-in-out 0.2s;
      width: 9px;
    }

    &:not(.active):after {
      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
    }
  }

  .info {
    align-items: center;
    background: #333;
    color: #fff;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    padding: 10px 20px;

    a {
      color: #fff;
    }

    .legal {
      padding-top: 1px;
      display: flex;

      a {
        white-space: nowrap;
      }
    }
  }

  .social {
    display: flex;
    a {
      display: block;
      float: left;
      transition: opacity 0.1s ease;
    }

    img {
      display: block;
      height: 100%;
    }

    a {
      &:hover {
        opacity: 0.8;
      }

      + a {
        padding-left: 8px;
      }
    }
  }

  .logos {
    background-color: #fff;
    @media screen and (max-width: $screen-md-max) {
      padding-right: 20px;
    }
    @media screen and (max-width: $screen-sm-max) {
      padding-left: 20px;
    }
  }

  .logos-inner {
    border-top: 4px solid #e7e7e7;
    display: flex;
    margin-left: 20px;
    padding: 25px 0 25px;

    @media screen and (min-width: $screen-lg-min) {
      margin-right: 20px;
    }
    @media screen and (max-width: $screen-xs-max) {
      border-top: 0;
      display: block;
      justify-content: center;
      padding: 20px 0;

      .logos-left {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 0 0 10px;
        width: 100%;

        .logo {
          padding: 0 10px;
          margin: 0;

          &:nth-child(1) {
            order: 2;
          }

          &:nth-child(2) {
            order: 3;
          }

          &:nth-child(3) {
            order: 1;
          }

          img {
            max-height: 60px;
            min-width: 70px;
            width: auto;
          }
        }
      }
    }
    // tablet
    @media screen and (max-width: $screen-sm-max) {
      margin-left: 0;
    }
  }

  .logos-left {
    align-items: center;
    display: flex;
    flex-direction: row;

    .logo {
      margin: auto 10px;

      img {
        width: 100px;
      }
    }
  }

  &.thin-footer {
    // large desktop
    @media screen and (min-width: $screen-lg-min) {
      .links .groups {
        padding: 0 20px 30px;
      }

      .info {
        padding: 10px 20px;
      }
    }
  }

  // tablet
  @media screen and (min-width: $screen-sm-min) {
    .contact {
      .pocs {
        border-bottom: 4px solid #ffe522;
        display: flex;
        align-items: baseline;
        justify-content: center;
        padding: 20px 0;

        li a {
          display: block;
          padding: 0 40px;
          box-sizing: border-box;
        }

        .mail {
          border-left: 2px solid #f7f7f7;
          border-right: 2px solid #f7f7f7;
        }
      }

      .poc {
        img {
          margin-bottom: 10px;
          max-height: 40px;
          max-width: 40px;
        }
      }
    }

    .links {
      .groups {
        display: -webkit-flex;
        display: flex;
        padding: 0 20px 30px;
      }

      .group {
        flex: 1;
        -webkit-flex: 1;

        &:not(:first-child) {
          padding-left: 20px;
        }
      }

      .groupname {
        font-size: 16px;
        font-weight: 500;
        .c-plusminus {
          display: none;
        }
      }

      ul a {
        font-size: 14px;
      }
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    .info {
      padding: 10px 10px 10px 100px;
    }
  }

  // mobile
  @media screen and (max-width: $screen-xs-max) {
    .contact .poc {
      border-bottom: 2px solid #f7f7f7;

      &:last-child {
        border-bottom: 4px solid #ffe522;
      }
    }

    .contact {
      .pocs {
        & > li a {
          padding: 20px;
          display: block;
          box-sizing: border-box;
        }

        .poc {
          img {
            margin-bottom: 10px;
            max-height: 60px;
            max-width: 30px;
          }
        }
      }
    }

    .links {
      a {
        display: block;
        font-size: 16px;
        padding: 10px 11px;
      }

      .group {
        border-bottom: 1px solid #f7f7f7;
        border-left: 4px solid #e7e7e7;

        ul {
          display: none;
        }

        &.active ul {
          display: block;
        }
      }

      .groupname {
        display: block;
        font-weight: 500;
        position: relative;

        .c-plusminus {
          display: block;
        }
      }
    }

    .info {
      flex-wrap: wrap;
      align-items: center;

      .copyright {
        order: 3;
        flex: 15;

        .copyright-info {
          // flex-direction: column;
          gap: 0;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: left;

          white-space: nowrap;
          p {
            white-space: nowrap;
          }
        }

        .copyright-divider {
          // display: none;
        }
      }
    }
  }
}

@media screen and (min-width: $screen-xlg) {
  .b-footer {
    .contact,
    .links,
    .logos,
    .info {
      max-width: 1860px;
      margin: 0 auto;
    }
  }
}

.copyright {
  display: flex;
  flex-direction: column;
  justify-content: left;

  p {
    font-size: 12px;
    white-space: nowrap;
  }
  .copyright-info {
    display: flex;
    gap: 3px;
    flex-wrap: wrap;
    align-items: left;
    justify-content: left;
    padding: 30px 0px;  
    @media (min-width: 768px) {
      justify-content: center;
      padding: 0;
    }
  }
  .copyright-divider {
    display: inline;
    margin: 0 5px;
  }
}

@media screen and (min-width: 480px) {
  .copyright {
    min-width: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .copyright {
    text-align: center;
    min-width: auto;
    width: auto;
  }
}

.narrow-footer{
  @media screen and (min-width: 992px) {
    max-width: 1860px;
    padding:0;
    padding-right:20px;
    margin: 0 auto;
    .contact,
    .links,
    .logos,
    .info {
      margin-left: calc(206px + 8px);
      @media screen and (min-width: 1360px) {
        width: calc(100% - 220px - 300px - 10px * 2);
      }
      @media screen and (min-width: 1900px) {
        width: calc(100% - 206px - 300px - 6px * 2);
      }
    }
  }
 

}