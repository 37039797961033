.c-category-filters-sort {
  display: flex;

  &__label {
    font-size: 12px;
    font-weight: 500;
    margin-top: 5px;
    margin-right: 8px;

    @media screen and (min-width: 992px) {
      font-size: 14px;
    }
  }

  &__select-wrap {
    @media screen and (max-width: 991px) {
      flex: 1;

      .c-stdselect {
        width: 100%;
      }
    }
  }
}
