@import '../../styles/breakpoints';

.NewModal {
  background-color: #fff6b2;
  min-height: 100px;
  z-index: 11;
  max-height: 80vh;
  width: 350px;
  overflow-y: auto;

  &:focus {
    outline: none;
  }
}

.Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.39);
  z-index: 10;
}

.c-newModal {
  position: relative;
 
  h2 {
    font-weight: 800;
  }
  &__title {
    flex: 10;
  }
  &__buttonContainer {
    margin: 16px 0;
    position: absolute;
    width: 100%;
    display:flex;
    justify-content: center;
    text-align: center;
    bottom:0;
  }

  &__close {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
  }

  &__specialText {
    font-size: 10px;
    font-weight: bold;
  }
}
