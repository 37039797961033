@import 'variables';

/* Helper classes */
.u-bold {
  font-weight: 600;
}
.u-font-size-base,
.font-size-base {
  font-size: $font-size-base; // 16px
}
.u-font-size-large,
.font-size-large {
  font-size: $font-size-large; // 18px
}
.u-font-size-sm,
.font-size-sm {
  font-size: $font-size-sm; // 14px
}
.u-font-size-xsm,
.font-size-xsm {
  font-size: $font-size-xsm; // 12px
}
.u-font-size-xxs,
.font-size-xxs {
  font-size: $font-size-xxs; // 10px
}
.u-first-to-upper:first-letter,
.first-to-upper:first-letter {
  text-transform: uppercase;
}
.u-img-fluid,
.img-fluid {
  height: auto;
  max-width: 100%;
}
.u-text-center,
.text-center {
  text-align: center;
}
.u-text-right,
.text-right {
  text-align: right;
}

.u-push-to-bottom,
.push-to-bottom {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.u-clear,
.clear {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

.u-full-width,
.full-width {
  width: 100%;
}

.u-full-width-xs,
.full-width-xs {
  @media screen and (max-width: $screen-xs) {
    width: 100%;
  }
}

.u-full-height,
.full-height {
  height: 100%;
}

.u-center-center,
.center-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.u-end-end,
.end-end {
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
}

.u-no-padding,
.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0;
}

@media screen and (max-width: $screen-xs) {
  .u-display-xs-1,
  .display-xs-1 {
    margin: map-get($spacers, 0);
    padding: map-get($spacers, 4);
    background-color: $color-white;
  }
}

.u-bg-color-white {
  background-color: $color-white;
}

.u-img-responsive,
.img-responsive {
  max-width: 100%;
  height: auto;
}

.u-is-block {
  display: block;
}

/*
 * A mixin targeting a specific device width and larger.
 * These are the available targets: 'xs', 'sm', 'md', 'lg', 'xl'
 *
 * @param {string} $media The target media
 */
@mixin respond-from($media) {
  @if $media == 'md-lg' {
    @media (min-width: 991px) {
      @content;
    }
  }
}

/*
 * Generates classes for a grid of columns, prefixed by size.
 * The result will be .column-[size]-[col]-[total-columns] E.g. .column-xs-6-12
 *
 * @param {string}	$size			The name of the size target (e.g. xs, sm, md, lg, xl, etc.)
 * @param {int}		$grid-columns	Amount of columns
 */
@mixin grid-generator($size, $grid-columns) {
  @for $i from 1 through $grid-columns {
    .table-column-#{$size}-#{$i}-#{$grid-columns} {
      width: percentage($i / $grid-columns);
      float: left;
    }
  }
}

@include grid-generator('xs-sm', 24);

@include respond-from('md-lg') {
  @include grid-generator('md-lg', 24);
}

.u-screen-xs-hide,
.screen-xs-hide {
  @media screen and (max-width: $screen-xs) {
    display: none !important;
  }
}
.u-screen-sm-hide,
.screen-sm-hide {
  @media screen and (min-width: $screen-xs) and (max-width: $screen-sm-max) {
    display: none !important;
  }
}
.u-screen-md-hide,
.screen-md-hide {
  @media screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    display: none !important;
  }
}
.u-screen-lg-hide,
.screen-lg-hide {
  @media screen and (min-width: $screen-lg-min) {
    display: none !important;
  }
}
