@import '../../../styles/variables';

.o-offerDetail {
  background: $color-white;
  margin-bottom: 16px;
  padding: 16px;

  @media (min-width: $screen-tablet) {
    display: flex;
    gap: 32px;
    padding: 16px 24px;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    position: relative;
    min-height: 100px;

    img {
      height: auto;
      max-width: 300px;
      max-height: 300px;
    }

    .price-stripe-container {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .price-tag-container {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    margin-top: -35px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;

    > div:last-of-type {
      @media (min-width: $screen-tablet) {
        display: inline-flex;
      }
    }

    h1 {
      margin: 0;
    }
  }
}

.o-offerProducts {
  background-color: $color-black-lightest;
  padding-bottom: 6px;

  @media (min-width: $screen-tablet) {
    padding-bottom: 24px;
  }

  &__title-no-flyout {
    font-size: 15px;
    margin-bottom: 16px;
    padding: 0 16px;

    @media (min-width: $screen-tablet) {
      font-size: 18px;
      margin: 24px 0;
      padding: 0;
    }
  }

  &__title-flyout {
    padding: 16px 24px 8px;
  }

  &__offer-product {
    background: $color-white;
    padding: 12px 12px 0;

    &::after {
      border-bottom: 1px solid $color-button-grey-static;
      content: '';
      display: block;
      padding-top: 11px;
      width: 100%;
      height: 1px;
    }
  }

  &__offer-product-flyout {
    @media (min-width: $screen-tablet) {
      margin: 0 24px;
    }
  }

  &__offer-product-last {
    padding: 12px 12px;

    &::after {
      content: '';
      display: none;
    }
  }
}
