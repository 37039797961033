.c-category-filters {
  background-color: #fff;
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 0 10px;
  min-height: 45px;

  &__top {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #f7f7f7;

    @media screen and (max-width: 991px) {
      display: block;
    }

    &--customfilter {
      @media screen and (max-width: 1100px) {
        display: block;
      }
    }
  }

  &__filters {
    display:flex;
    align-items: center;
    > * {
      margin-right: 10px ;
    }
    :last-child{
      margin-right : 0 ;
    }
  }



  &__customsort {
    display: inline-flex;
    margin-left: 10px;
    align-items:center;

    @media screen and (max-width: 1100px) {
      display: inline-flex;
      margin-right: 0;
      margin-top: 12px;
    }

    @media screen and (max-width: 680px) {
      margin-left: 0;
    }

    div {
      display: inline-block;
      margin-left: 10px;
    }
  }

  &__sort {
    margin-left: auto;
    display:block;
    @media screen and (max-width: 1100px) {
      margin-top: 10px;
      display:none;
  
    }
    
    &--mobile{
      margin-right:0;
      @media screen and (min-width: 1100px) {
        display:none;
      }
    }
  }

  &__bottom {
    padding-bottom: 5px;
  }
}
