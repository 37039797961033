.nav {
  background-color: #5f5f5f;
  min-height: 100%;
}

body .buorg {
  background-color: white;
}

body .buorg-buttons {
  margin-top: 12px;
  margin-bottom: 12px; 
}

a {
  -webkit-tap-highlight-color: transparent;
}

button:focus-visible {
  // @TODO remove, needed for FE components, but should be fixed in the component library
outline:  -webkit-focus-ring-color auto 1px !important;

}
