@import '../../styles/variables';

/* Component: price tag */
// TODO: Get Dom Casual font && make SCSS
.c-pricetag-grid{
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 10px;
  line-height: 16px;
}
.c-pricetag-search{
  padding-top: 0px;
}