.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999999999;
    background-color: #efefef;

    .square {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 98px;
        padding-right: 98px;
        padding-top: 48px;
        padding-bottom: 48px;
        background-color: white;
        max-width: 750px;
        width: 100%;
        text-align: center;

        h1 {
            font-weight: bold;
            width: 100%;
        }

        a {
            color: #0072BB;
            width: 100%;
        }

        span {
            font-weight: bold;
            width: 100%;
        }

        img {
            margin-top: 24px;
            width: 100%;
        }

        p {
            margin-top: 24px;
            width: 100%;
        }
    }
}