/* Template: find store */
@import '../../styles//variables';

/* Component: find store */
.c-findstore{
  @media screen and (max-width:768px) {
    margin-bottom:20px;
  }

  &__image {
    margin: 0 -10px;
    height: 500px;

    @media screen and (max-width: 767px) {
      height: 250px;
    }
  }

  &__header {
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    justify-content: center;

    @media screen and (max-width:480px) {
      display: block;
    }
  }

  &__headercol {
    max-width: 500px;
    padding: 15px;

    h2 {
      font-size: 20px;
    }
    p {
      font-size: 15px;
    }
  }

  &__search {
    background: #f5f5f5;
    padding: 20px;
    display: flex;
  }
  &__searchbar {
    display: flex;
    flex: 1;
    margin-right: 15px;
    input {
      width: 100%;
      padding-left: 10px;
    }
  }
  &__searchicon {
    background: #e8e8e8;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &__links {
    position: relative;
    background: #fff;
    padding: 20px;
    margin: -40px 5px 40px;

    @media screen and (min-width:991px) {
      margin: -100px 15px 40px 15px;
      min-height: 200px;
    }

    @media screen and (min-width:768px) and (max-width:991px) {
      margin: -50px 15px 40px 15px;
      min-height: 200px;
    }

    @media screen and (max-width:480px) {
      padding: 0;
      margin: -40px -10px 40px;
    }
  }

  &__container {
    max-width: 980px;
    margin: 0 auto;
  }

  &__store {
    padding: 20px;
    border-top: 1px solid #eaeaea;
    position: relative;

    @media screen and (min-width:992px) {
      display: flex;
      flex-wrap: wrap;
    }

    
    h2 {
      font-size: 18px;
      padding: 0;
      margin: 0;  
    }
    p {
      font-size: 14px;
      font-weight: 500;
    }
    a {
      color: $color-blue-dark;
    }
  }

  &__storecolumn {
    display: flex;

    &:first-child {
      flex: 1;
      padding-right: 40px;
    }
    &:last-child {
      justify-content: flex-end;

      @media screen and (max-width:992px) {
        margin-top: 10px;
      }
    }
  }

  &__actionbtn {
      @media screen and (max-width:480px) {
        width: 100%;
      }
  }

  &__storeimage {
    width: 160px;
    margin-right: 20px;

    @media screen and (max-width:680px) {
      display: none;
    }
  }

  &__distance {
      position: absolute;
      top: 20px;
      left: 0;
      background: #f0f0f0;
      color: #000000;
      padding: 5px;


      @media screen and (max-width:680px) {
        right: 0;
        left: auto;
        top: 0;
      }


  }
}
