// Make the breakpoints
@mixin bp($breakpoint) {
  $query: map-get($mq-list, $breakpoint);
  @if $query != null {
    @media ($mq-width: #{nth($query, 1)}) {
      @content;
    }
  }
}

// Function to generate the grid by number
@mixin makeGridByNumber($grid) {
  @for $i from 1 through $colCount {
    [#{$attributeName}*='#{$grid}-#{$i}'] > [#{$attributeName}~='#{$colName}'],
    [#{$attributeName}*='#{$grid}-#{$i}'] > [#{$attributeName}*='#{$colName}-'],
    [#{$attributeName}*='#{$grid}-#{$i}']
      > [#{$attributeName}*='#{$colName}_'] {
      $fraction: 1 / $i;

      flex-basis: map-get($colFractions, $fraction);
      max-width: map-get($colFractions, $fraction);
    }
  }
}

// Function to generate the grid by columns
@mixin makeCol($col) {
  [#{$attributeName}~='#{$gridName}'],
  [#{$attributeName}*='#{$gridName}-'] {
    @for $i from 1 through $colCount {
      > [#{$attributeName}*='#{$col}-#{$i}'] {
        flex-basis: map-get($colPortions, $i);
        max-width: map-get($colPortions, $i);
      }
    }
  }
}

// Function to generate the -first & -last base on mq keys (as _lg-first)
@mixin makeFirstLast($mq) {
  [#{$attributeName}~='#{$gridName}'],
  [#{$attributeName}*='#{$gridName}-'] {
    [#{$attributeName}*='#{$mq}-first'] {
      order: -1;
    }
    [#{$attributeName}*='#{$mq}-last'] {
      order: 1;
    }
  }
}

// Function to generate the offset for a col
@mixin makeOff($off) {
  [#{$attributeName}~='#{$gridName}'],
  [#{$attributeName}*='#{$gridName}-'] {
    @for $i from 0 through $colCount - 1 {
      > [data-push-left*='#{$off}-#{$i}'] {
        margin-left: map-get($colPortions, $i);
      }
    }
  }

  [#{$attributeName}~='#{$gridName}'],
  [#{$attributeName}*='#{$gridName}-'] {
    @for $i from 0 through $colCount - 1 {
      > [data-push-right*='#{$off}-#{$i}'] {
        margin-right: map-get($colPortions, $i);
      }
    }
  }
}
