@import '../../styles/variables';

.c-homelist {
  padding-top: map-get($spacers, 2);

  h3 {
    margin-bottom: 0;
    padding-left: map-get($spacers, 5);
    font-weight: 500;
    @media screen and (min-width: $screen-md-min) {
      padding-left: (map-get($spacers, 5) * 2);
    }
  }

  &__nonbound {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    font-size: $font-size-sm;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: inherit;
    transition: height 0.2s ease-in-out;
    border-bottom: 1px solid $color-black-lighter;
    padding: 5px 0 5px;
    .img {
      height: 5rem;
      padding: map-get($spacers, 1);
      display: flex;
      justify-content: center;
    }
    span {
      font-size: $font-size-sm;
      @media screen and (min-width: $screen-md-min) {
        font-size: $font-size-base;
      }
      line-height: 1.3;
      margin-top: 3px;
    }
  }
}
