/* Component: spot card */

.c-spotcard {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  transition: ease-in-out .2s;
  color: white;

  &__image {
    height: 100%;
    width: 100%;

    @media screen and (max-width: 767px) {
      min-height: 300px;
    }
  }

  a:hover & {
    box-shadow: 0 0 6px 4px #e7e7e7;
  }

  .gradient {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 196px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .75));
  }

  .text {
    position: absolute;
    bottom: 17px;
    left: 11px;
    right: 11px;
    font-size: 26px;
    font-weight: 500;
    line-height: 1.43;
    text-align: center;
    white-space: pre-line;
  }
}
