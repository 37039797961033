/* Parts of Reboot: https://github.com/twbs/bootstrap/blob/v4-dev/dist/css/bootstrap-reboot.css */
html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
body {
  min-width: 360px;
  width: 100%;
  padding: 0;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #f7f7f7;
  overflow-x: hidden;
  overflow-y: scroll;
}

/* Defaults */
body,
input,
select,
textarea,
button {
  color: #333;
  font-family: 'Poppins', sans-serif;
  line-height: normal;
}
h1,
h2,
h3 {
  line-height: 1.3;
  font-weight: 600;
}
a {
  color: #333;
  text-decoration: none;
}
p {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
li {
  display: block;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='search'] {
  -webkit-appearance: none;
} /* Safari 7-9 rounded corners */
input::placeholder {
  color: #333;
}
input::-webkit-input-placeholder {
  color: #333;
} /* Safari 8-9 */
input:-ms-input-placeholder {
  color: #333;
} /* IE 11 */
input::-ms-input-placeholder {
  color: #333;
} /* Edge */
