.c-removebtn {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 20px;
  background: #e7e7e7;
  background-image: url('../../assets/Trash.svg');
  background-size: 20px 20px;
  background-position: 6px 5px;
  background-repeat: no-repeat;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.2s;
  cursor: pointer;
  position: relative;
  vertical-align: top;

  &--dark-icon {
    background-image: url('../../assets/trashcan_dark.svg');
  }

  &--close-icon {
    background-image: url("../../assets/remove.svg");
  }

  &:hover {
    background-color: #ffe876;
  }
  &.mini {
    width: 20px;
    height: 20px;
    background-size: 12px 12px;
    background-position: 4px 4px;
  }
}
