@keyframes roll {
  0% {
    opacity: 1;
    top: 0;
  }

  25% {
    opacity: .3;
    top: -3px;
  }

  50% {
    opacity: 0;
    top: -7px;
  }

  51% {
    opacity: 0;
    top: 7px;
  }

  75% {
    opacity: .3;
    top: 3px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

.rollup-enter {
  &.rollup-enter-active {
    animation: roll .2s reverse;

    &.reverse {
      animation: roll .2s;
    }
  }
}

.rollup-exit {
  opacity: 1;
  top: 0;
}
