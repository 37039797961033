$number-of-cols: 10;

.c-frm-col {
  padding-right: 7.5px;
  padding-left: 7.5px;
  min-height: 1px;
  width: 100%;

  // Fixed widths cols .c-frm-col--{1-10}
  @for $i from 1 through $number-of-cols {
    &--#{$i} {
      flex-basis: 0;
      flex-grow: 1;

      flex: 0 0 percentage($i / $number-of-cols);
      max-width: percentage($i / $number-of-cols);
    }
  }

  // Fixed width cols, only above bp sm
  @for $i from 1 through $number-of-cols {
    &--sm-#{$i} {
      @media screen and (min-width: 544px) {
        flex-basis: 0;
        flex-grow: 1;

        flex: 0 0 percentage($i / $number-of-cols);
        max-width: percentage($i / $number-of-cols);
      }
    }
  }

  // Auto (equal) width, only above bp sm
  &--sm {
    @media screen and (min-width: 544px) {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%;
    }
  }

  // Auto (equal) width
  &--auto {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  &--flex {
    display: flex;
  }

  &--align-bottom {
    align-items: flex-end;
  }

  &--align-center {
    align-items: center;
  }

  &--align-top {
    align-items: flex-start;
  }
}
