.c-favoritebtn {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 0;
  border-radius: 20px;
  background: #e7e7e7;
  background-repeat: no-repeat;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
  transition: ease-in-out 0.2s;
  cursor: pointer;
  position: relative;
  top: -5px;
  vertical-align: top;
  background-image: url('../../assets/favorite-outlined.svg');
  background-size: 26px 26px;
  background-position: 3px 4px;
  text-align: center;

  &:hover:not(.active) {
    background-color: #fede53;
  }
  &.active {
    background-image: url('../../assets/favorite-filled.svg');
  }

  &.slideToggle {
    width: 64px;

    &.active {
      background-position: 33px;
    }
  }

  &.loading {
    background-image: none;
  }

  &.light:not(:hover:not(.disabled)) {
    background-color: white;
  }

  &__spinner {
    margin-top: 3px;
    width: 26px;
    height: 26px;
    animation: rotate 0.75s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  &.disabled {
    cursor: auto;
  }
}
