.c-notice-bar {
  position: static;
  color: white;
  height: 100%;
  display: none;

  &::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  &.active {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .c-notice-bar__button {
      height: 36px;
      visibility: visible;
    }
  }
  
  &__button {
    visibility: hidden;

    .c-closebtn {
      filter: brightness(0) invert(1);
      height: 36px;
      width: 36px;
      background-position: 8px 7px;
    }
  }

  &__container {
    padding: 0 13px;
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1860px;
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      align-items: flex-start;
    }

    .c-richtext {

      a {
        color: #ffe523;
        text-decoration: underline;
      }
    }
  }
}
