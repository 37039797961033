.b-responsive-image {
  &__link {
    display: block;
  }

  &__caption {
    .c-center {
      position: relative;
    }

    .header {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: rgba(120, 120, 120, 0.75);
      padding: 20px 60px 20px 15px;

      h1 {
        margin: 0;
        color: white;
      }
    }

    @media screen and (max-width: 565px) {
      .header {
        position: relative;
      }
    }
  }
}
