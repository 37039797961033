@import '../../styles/variables';

.c-availability {
  display: flex;
  align-items: center;
  gap: 4px;

  &.background {
    background-color: #fff7d1;
    padding: 3px 8px;
    border-radius: 20px;
  }
}
