.login-error-box{
  &__container{

    display: flex;
    gap: 16px;
  }

  &__content{
    display: flex;
    flex-direction: column;
    gap: 4px
  }
}
