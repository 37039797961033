@import '../../../styles/variables';

.m-searchresultpage {
  min-height: 800px;
  display: flex;

  h1 {
    @media screen and (max-width: 467px) {
      font-size: 18px;
      padding: 11px 0;
    }
  }
  &__sidebar {
    width: 216px;
    padding: 0 10px;
    margin-right: 10px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  }
  &__mobilenav {
    display: none;
    @media screen and (max-width: 991px) {
      display: block;
      button {
        width: calc(25% - 10px);
        margin-left: 5px;
      }
    }
    @media screen and (max-width: 767px) {
      button {
        width: calc(50% - 5px);
        margin-left: 0px;
      }
    }
    button {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  &__content {
    flex: 1;
    @media screen and (min-width: 992px) and (max-width: 1860px) {
      margin-right: 20px;
    }
    h1 {
      font-size: $h2-font-size;
      margin-top: 20px;
      margin-bottom: 15px;
      @media screen and (min-width: $screen-md-min) {
        margin-top: 30px;
        margin-bottom: 32px;
      }
    }
  }

  &__navitem {
    background: #e0e0e0;
    padding: 13px 10px;
    font-weight: 500;
    cursor: pointer;

    span {
      float: right;
      font-size: 11px;
      line-height: 26px;
    }
    &.active,
    &:hover {
      background: $color-yellow;
      transition: background 0.5s ease-in-out;
    }
  }
}

.search-no_found{
 &__container{
  max-width: 300px;
  margin: 0 auto;
 }
 &__buttons{
  display:flex;
  justify-content: space-around;
 }
}