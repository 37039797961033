@import '../../../styles/breakpoints';

.offer-listing {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (max-width: $screen-phone) {
    padding: 10px;
    width: 100%;
  }

  .offer-dates-container {
    font-size: 13px;
    line-height: 19.5px;
  }

  .filter-container {
    display: flex;
    gap: 8px;

    @media screen and (max-width: $screen-phone) {
      flex-wrap: nowrap;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 12px;

      > * {
        white-space: nowrap;
      }

      &::-webkit-scrollbar {
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #333;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #d6d6d6;
      }
    }

    @media screen and (min-width: $screen-phone) {
      flex-wrap: wrap;
    }
  }

  .spinner-container {
    justify-self: center;
    padding: 10px;
  }
}
