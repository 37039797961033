@import '../../../styles/variables';

.c-recipe-directions {
  font-size: $font-size-sm;
  li {
    margin-bottom: 1.7em;
  }

  h3 {
    font-weight: 500;
  }

  @media screen and (min-width: 768px) {
    float: right;
    width: calc(100% - 250px - 20px);
  }
}
