@import '../../../styles/colors';

.c-cartdeliverybox {
  text-align: left;
  font-size: 14px; 
  position: relative;

  &__heading {
    font-weight: 500;
  }

  &__edit {
    color: $color-blue-dark;
    position: absolute; 
    right: 0;
    top: 0;
    cursor: pointer;

    &:hover {
      color: $color-blue-darker;
    }
  }

  &__deliveryslots {
    color: $color-blue-dark;
    display: flex;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;

    &:hover {
      color: $color-blue-darker;
    }
    
    img {
      width: 25px;
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
  }
}

.c-cart-first-delivery-time{
  font-size: 15px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.c-cart-first-delivery-icon{
 display: flex;
 align-items: center;
}
.c-cart-delivery__regular{
  font-weight: 500;
  margin-right: 4px;
}
.c-cart-delivery-time-divider{
  color: #DBDBDB
}

.c-cart-delivery-time-label{
  white-space: nowrap;
}

.c-cart-delivery-box{
  padding: 12px;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f7f7f7;
  border-radius: 5px;
  flex-wrap:wrap;
}

.c-change-delivery-link {
  color: #0069AE;
  font-size: 15px;
  cursor: pointer;
  text-decoration: underline;

  &:hover{
    color: #005C99;
  }
}

.c-cart-delivery-separator{
  display: none;
  @media(min-width: 991px){
    display: inline;
  }
}

.delivery-summary-hide-m{
  display:none;
  @media(min-width: 991px){
    display: inline;
  }
}