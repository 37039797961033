@import '../../styles/variables';

.c-loadmore {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  
  &__status {
    font-size: 12px;
  }

  &__button {
      margin-left: 20px;
  }

  &__bar {
    height: 14px;
    width: 86px;
    border-radius: 7px;
    background-color: $color-grey;
  }

  &__progress {
    width: 0;
    height: 14px;
    border-radius: 7px;
    background-color: $color-blue;
  }
}
