@import '../Skeletons.scss';

.card{
  width: 100%;
}

.c-skeleton__prodcard {
  $prod-card-height: 340px;

  $prod-image-height: 115px;
  $prod-image-skeleton: linear-gradient($color-grey $prod-image-height, transparent 0);

  $prod-desc-line-height: 16px;
  $prod-desc-line-skeleton: linear-gradient($color-grey $prod-desc-line-height, transparent 0);
  $prod-desc-line-width: 85%;
  $prod-desc-line-1-width: $prod-desc-line-width + 5%;
  $prod-desc-line-2-width: $prod-desc-line-width - 5%;
  $prod-desc-line-1-position: $card-padding calc(#{$prod-image-height} + #{$card-padding});
  $prod-desc-line-2-position: $card-padding calc(#{$prod-image-height} + #{$card-padding} + #{$prod-desc-line-height} + 5px);

  $prod-button-width: calc(100% - #{$card-padding} - #{$card-padding});
  $prod-button-position: $card-padding calc(100% - #{$card-padding});
  $prod-button-skeleton: linear-gradient($color-grey $button-height, transparent 0);

  $prod-blur-size: $blur-width $prod-card-height;

  min-height: 327px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;

  &::after {
    content:"";
    display:block;
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0 1px 0 0 #d7d7d7;
    // box-shadow: 0 10px 45px rgba(0,0,0, .1);

    background-image:
      linear-gradient(
        90deg,
        rgba(white, 0) 0,
        rgba(white, .33) 100%
      ),                        //animation blur
      $prod-desc-line-skeleton, //desc1
      $prod-desc-line-skeleton, //desc2
      $avatar-skeleton,         //avatar
      $prod-button-skeleton,    //button
      $prod-image-skeleton      //card
    ;

    background-size:
      $prod-blur-size,
      $prod-desc-line-1-width $prod-desc-line-height,
      $prod-desc-line-2-width $prod-desc-line-height,
      $avatar-size $avatar-size,
      $prod-button-width $button-height,
      100% 100%
    ;

    background-position:
      calc(-100% - #{$blur-width} - #{$card-padding}) 0, //animation
      $prod-desc-line-1-position,   //desc1
      $prod-desc-line-2-position,   //desc2
      $avatar-position,             //avatar
      $prod-button-position,        //button
      0 0                           //card
    ;

    background-repeat: no-repeat;
    animation: loadingprodcard 1.2s infinite;

    @keyframes loadingprodcard {
      to {
        background-position:
          calc(100% + #{$blur-width} + #{$card-padding}) 0,
          $prod-desc-line-1-position,
          $prod-desc-line-2-position,
          $avatar-position,
          $prod-button-position,
          0 0
        ;
      }
    }
  }
}
