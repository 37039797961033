@import '../../../styles/variables';

@media screen and (min-width: 992px) {
  .c-category-suggestions--is-sidebar:first-of-type {
    .c-category-suggestions__categorized-item {
      margin-top: 0.9375rem;
    }
  }
  .c-category-suggestions--is-sidebar {
    .c-category-suggestions__categorized-item:first-of-type {
      margin-top: 0.9375rem;
    }
  }
}
