@import '../../../styles/_variables.scss';

.filter {
  background-color: #fff;
  box-sizing: border-box;
  margin-top: 2px;
  margin-bottom: 0px;
  overflow: hidden;
  border-color: #c7c7c7;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  box-shadow: 0px 1px 1px #c7c7c78e;
  &[disabled] {
    margin-top: 1px;
  }

  &.active {
    margin-top: 1px;
  }
  &.ekologiskt {
    &.active {
      background-color: $color-eko;
      box-shadow: inset 0 1px 0 0 darken($color-eko, 6%);
    }
    &:active:not([disabled]) {
      background-color: lighten($color-eko, 6%);
      box-shadow: 0 2px 0 0 darken($color-eko, 6%);
    }
    @media (hover:hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover:not([disabled]) {
        color: #fff;
        background-color: lighten($color-eko, 6%);
        box-shadow: 0 2px 0 0 darken($color-eko, 6%);
      }
    }

  }
  &.laktosfritt {
    &.active {
      color: #fff;
      background-color: $color-lactose-free;
      box-shadow: inset 0 1px 0 0 darken($color-lactose-free, 6%);
    }
    &:active:not([disabled]) {
      background-color: lighten($color-lactose-free, 6%);
      box-shadow: 0 2px 0 0 darken($color-lactose-free, 6%);
    }
    @media (hover:hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover:not([disabled]) {
        color: #fff;
        background-color: lighten($color-lactose-free, 6%);
        box-shadow: 0 2px 0 0 darken($color-lactose-free, 6%);
      }
    }

  }
  &.glutenfritt {
    &.active {
      color: #fff;
      background-color: $color-gluten-free;
      box-shadow: inset 0 1px 0 0 darken($color-gluten-free, 6%);
    }
    &:active:not([disabled]) {
      background-color: lighten($color-gluten-free, 6%);
      box-shadow: 0 2px 0 0 darken($color-gluten-free, 6%);
    }
    @media (hover:hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover:not([disabled]) {
        color: #fff;
        background-color: lighten($color-gluten-free, 6%);
        box-shadow: 0 2px 0 0 darken($color-gluten-free, 6%);
      }
    }
  }
  &.vegetariskt, &.veganskt {
    &.active {
      color: #fff;
      background-color: $color-vegetarian-free;
      box-shadow: inset 0 1px 0 0 darken($color-vegetarian-free, 6%);
    }
    &:active:not([disabled]) {
      background-color: lighten($color-vegetarian-free, 6%);
      box-shadow: 0 2px 0 0 darken($color-vegetarian-free, 6%);
    }
    @media (hover:hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover:not([disabled]) {
        color: #fff;
        background-color: lighten($color-vegetarian-free, 6%);
        box-shadow: 0 2px 0 0 darken($color-vegetarian-free, 6%);
      }
    }
  }
  &.default {
    &.active {
      background-color: $color-blue-lightest;
      border: 1px solid $color-blue;
      box-shadow: inset 0 1px 0 0 darken($color-blue-lightest, 6%);
    }
    &:active:not([disabled]) {
      background-color: lighten($color-blue-lighter, 6%);
      box-shadow: 0 2px 0 0 darken($color-blue-lighter, 6%);
    }
    @media (hover:hover) {
      /* Primary Input responds to hover fully, such as a mouse or a Nintendo Wii controller */
      &:hover:not([disabled]) {
        background-color: lighten($color-blue-lighter, 6%);
        box-shadow: 0 2px 0 0 darken($color-blue-lighter, 6%);
      }
    }
  }
}
