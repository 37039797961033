.b-recipespot {
  position: relative;
  background: center center no-repeat #333;
  color: white;
  display: block;

  @media screen and (min-width: 768px) {
    padding: 30px 0;
  }

  @media screen and (max-width: 991px) {
    margin-left: -10px;
    margin-right: -10px;
  }

  @media screen and (min-width: 992px) {
    margin-left: -20px;
    margin-right: -20px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  &__body {
    position: relative;
    z-index: 1;
  }

  .name {
    font-weight: 600;
    line-height: 1.3;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 767px) {
      font-size: 32px;
      padding: 10px;
    }

    @media screen and (min-width: 768px) {
      font-size: 36px;
      padding: 0 20px 20px;
    }

    @media screen and (min-width: 1360px) {
      max-width: calc(800px - 210px);
      font-size: 46px;
      padding-left: 0;
    }
  }

  .content {
    background: rgba(51, 51, 51, 0.5);

    @media screen and (max-width: 767px) {
      padding: 10px;
    }

    @media screen and (min-width: 768px) {
      overflow: hidden;
      padding: 20px 20px 20px 230px;
    }

    @media screen and (min-width: 1360px) {
      max-width: 800px;
      //margin-left: calc(-210px - 20px);
    }

    .expression {
      text-align: center;
      line-height: 100%;
      transform: rotate(-12deg);
      font-family: 'Peekaboo', sans-serif;

      @media screen and (max-width: 767px) {
        font-size: 33px;
        float: right;
        width: 190px;
        margin-top: 17px;
      }

      @media screen and (min-width: 768px) {
        font-size: 48px;
        line-height: 80%;
        float: left;
        width: calc(230px - 20px * 2);
        margin-left: -210px;
        margin-top: 20px;
      }

      img {
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }

    .description {
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }

      @media screen and (min-width: 768px) {
        margin-bottom: 40px;
      }
    }

    .meta {
      font-size: 14px;
      @media screen and (max-width: 767px) {
        margin-bottom: 10px;
      }

      @media screen and (min-width: 768px) {
        float: left;
      }
    }

    .shop {
      @media screen and (min-width: 768px) {
        float: right;
        margin-top: -13px;
      }
    }

    .c-pricetag {
      display: inline-block;
      margin-right: 20px;
      vertical-align: bottom;

      .value .fractions {
        border-bottom-color: white;
      }
    }
  }
}
