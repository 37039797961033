.c-navigation {
  display: block;
  height: 100%;
  background: white;
  overflow-y: scroll;
  padding-top: 10px;

  &__title {
    display: block;
    padding: 12px 14px;
    padding-top: 13px;
    background-color: #e7e7e7;
    font-size: 16px;
    font-weight: 500;

    @media screen and (max-width: 991px) {
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
    }
    &.active {
      background-color: #ffe522;
    }
    span {
      position: absolute;
      right: 10px;
      top: calc(50% - 4px);
    }
  }

  &__scroll-lock {
    height: calc(100% - 56px);
  }

  &__scroll {
    height: 100%;
  }
}
.b-secondary-nav > .c-navigation > .c-navigation__title > .c-droparrow {
  display:none;
}

