.c-createaccount {
  &__head {
    margin-bottom: 2em;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .formfield.text input {
    border: 1.5px solid #c4c4c4;
  }
  
  img {
    max-width: 75px;
  }
  a {
    color:#0071b9;
    &:hover {
      text-decoration:underline;
    }
  }
}
