@import '../../styles/variables';

.c-producttable {
  font-size: $font-size-xsm;
  padding: 0 map-get($spacers, 5);
  &__labels {
    display: none;
    padding: map-get($spacers, 2) 0 0;
    @media screen and (min-width: 768px) {
      font-weight: 500;
      display: flex;
    }
  }
}
